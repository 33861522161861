import { environment } from 'src/environments/environment';
import { Observable, ReplaySubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// [[Providers]]
import { ErrorService } from '~services/error.service';
// ]
@Injectable()
export class ClientMarketplaceCoreProductService {
  authenticated_user: any;

  constructor(private router: Router, public http: HttpClient, public error: ErrorService) {
    this.authenticated_user = JSON.parse(localStorage.getItem('client_marketplace_customer_user'));
  }

  searchSubject = new ReplaySubject(1);
  setSearch(objectData: Object) {
    this.searchSubject.next(objectData);
  }

  getSearch() {
    return this.searchSubject.pipe(debounceTime(300));
  }

  searchResultSubject = new ReplaySubject(1);
  setSearchResult(objectData: Object) {
    this.searchResultSubject.next(objectData);
  }

  getSearchResult() {
    return this.searchResultSubject;
  }

  /**
   *
   * @param data
   */
  getQuerystring(data: any) {
    let querystring: any = '';
    for (let entry in data) {
      querystring += entry + '=' + encodeURIComponent(data[entry]) + '&';
    }
    return querystring;
  }

  /**
   *
   * @param data
   */
  getPost(data: any) {
    let postData: any = {};
    for (let entry in data) {
      if (typeof data[entry] == 'object') {
        if (data[entry] == null) {
          postData[entry] = null;
        } else {
          postData[entry] = JSON.stringify(data[entry]);
        }
      } else {
        postData[entry] = data[entry];
      }
    }
    return postData;
  }

  /**
   *
   * @param data
   */
  index(data: any) {
    if (this.authenticated_user != null) {
      data.client_marketplace_customer_id = this.authenticated_user.client_marketplace_customer_id;
    }

    let querystring = this.getQuerystring(data);

    let request = this.http.get(environment.api_url + 'collective/client/marketplace/' + environment.marketplace.hostname + '/core/product?' + querystring, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return request;
  }

  /**
   *
   * @param data
   */
  view(data: any) {
    if (this.authenticated_user != null) {
      data.client_marketplace_customer_id = this.authenticated_user.client_marketplace_customer_id;
    }
    data.referer = document.referrer;

    let querystring = this.getQuerystring(data);

    let request = this.http.get(environment.api_url + 'collective/client/marketplace/' + environment.marketplace.hostname + '/core/product/' + data.id + '?' + querystring, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return request;
  }

  /**
   *
   * @param data
   */
  price_delivery(data: any) {
    let querystring = this.getQuerystring(data);

    let request = this.http.get(environment.api_url + 'collective/client/marketplace/' + environment.marketplace.hostname + '/core/product/' + data.id + '/price_delivery?' + querystring, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return request;
  }

  /**
   *
   * @param data
   */
  search(data: any) {
    return new Observable((Observable) => {
      this.index(data).subscribe(
        (response: any) => {
          var facetDistribution: any = {};
          Object.keys(response.data.data.facetDistribution).forEach((facet) => {
            facetDistribution[facet] = [];
            Object.keys(response.data.data.facetDistribution[facet]).forEach((element) => {
              facetDistribution[facet].push({
                index: element,
                value: response.data.data.facetDistribution[facet][element],
              });
            });
          });

          var facetStats: any = {};
          Object.keys(response.data.data.facetStats).forEach((facet) => {
            facetStats[facet] = [];
            Object.keys(response.data.data.facetStats[facet]).forEach((element) => {
              facetStats[facet].push({
                index: element,
                value: response.data.data.facetStats[facet][element],
              });
            });
          });

          Observable.next({
            data: response.data.data.hits,
            facetDistribution: facetDistribution,
            facetStats: facetStats,
            current_page: response.data.current_page,
            from: response.data.from,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
            to: response.data.to,
            total: response.data.total,
          });
        },
        (error) => {
          Observable.next(error);
        }
      );
    });
  }
}
