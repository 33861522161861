import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'zipcode',
})
export class ZipcodePipe implements PipeTransform {
  transform(value: string | number): string {
    let cep = value.toString();

    if (cep.length === 8) {
      // Formata o CEP no padrão '00000-000'
      return cep.replace(/(\d{5})(\d{3})/, '$1-$2');
    }

    return cep;
  }
}
