declare let iPORTO_ApplicationGears_Measure: any;
import { environment } from 'src/environments/environment';
import { Component, EventEmitter, OnInit, OnChanges, Input, Output, ViewChild, ElementRef } from '@angular/core';
//[PrimeNG]
import { MessageService } from 'primeng/api';
// [[Providers]]
import { CustomerUserService } from 'src/project/services/panel/client/marketplace/customer/user/user.service';
import { CollectiveMarketplaceCartService } from '~services/collective/client/marketplace/cart/cart.service';
import { ClientMarketplaceCartProductService } from '~services/collective/client/marketplace/cart/product/product.service';
import { CustomerWishlistService } from '~services/panel/client/marketplace/customer/wishlist/wishlist.service';
import { CustomerNoticeService } from '~services/panel/client/marketplace/customer/notice/notice.service';
import { Router } from '@angular/router';
// ]
@Component({
  selector: 'IncludeProductContainer',
  templateUrl: './component.html',
  styleUrls: ['./component.css'],
})
export class FirstProductContainerComponent implements OnInit {
  @Input() cart: any;
  @Input() product: any;
  @Output() fncDataIndex = new EventEmitter<object>();
  @Output() customerWishlistServiceIndex = new EventEmitter<void>();
  profile: any;
  messageProcess = false;
  messageIsError: any;
  displayModal: boolean;
  route: any;

  constructor(
    //
    private router: Router,
    private userService: CustomerUserService,
    public collectiveMarketplaceCartService: CollectiveMarketplaceCartService,
    public clientMarketplaceCartProductService: ClientMarketplaceCartProductService,
    public customerWishlistService: CustomerWishlistService,
    public customerNoticeService: CustomerNoticeService,
    public messageService: MessageService
  ) {}

  /**
   * on init
   */
  ngOnInit() {
    this.ipBuildEcommerceDependencies();
    this.route = this.router.url;
  }

  ipBuildEcommerceDependencies() {
    /**
     * Base
     *
     * Request da base de uso.
     */
    // PROFILE
    this.userService.getUser().subscribe((Profile: any) => {
      if (Profile != null) {
        if (JSON.parse(Profile) != null) {
          this.profile = JSON.parse(Profile);
        }
      }
    });
  }

  /**
   * on changes
   */
  ngOnChanges() {
    this.createComponent();
  }

  ngAfterViewInit() {}

  /**
   * Creates component
   */
  createComponent() {}

  /**
   * Gets cart
   */
  getCart() {
    /**
     * Cart
     *
     * Request inicial do CARRINHO para acompanhamento nas Páginas.
     */
    this.collectiveMarketplaceCartService.cart({ client_marketplace_cart_uid: this.cart.client_marketplace_cart_uid }).subscribe((Cart: any) => {
      if (Cart != null) {
        this.collectiveMarketplaceCartService.setCart(Cart.data);
      }
    });
  }
  productAddedToCart: any;
  productHasBeenBought: any = false;
  /**
   * Clients marketplace cart product service create
   */
  clientMarketplaceCartProductServiceCreate(product: any) {
    this.messageProcess = true;
    this.productAddedToCart = product;
    var _ = this.clientMarketplaceCartProductService.store({
      client_marketplace_cart_uid: this.cart.client_marketplace_cart_uid,
      client_marketplace_core_product_id: this.product.id,
      inventory_quantity: this.inventoryQuantity,
    });
    if (_ !== null) {
      _.subscribe(
        (response: any) => {
          /**
           * * Automação iPORTO
           */
          if (environment.production) {
            if (this.profile !== undefined) {
              if (this.profile.hasOwnProperty('email')) {
                var iPORTO_ApplicationGears_Measure_behavior_Attributes = {
                  id: '6665f039-c09f-41b7-9f3e-38bdba0d5008',
                  customer_application_contact: {
                    email: this.profile.email,
                  },

                  value: this.inventoryQuantity * this.product.price,
                  currency: 'BRL',

                  cart: {
                    id: this.cart.client_marketplace_cart_uid,
                  },

                  items: [
                    {
                      id: this.product.id,
                      title: this.product.name,
                      image: this.product.image_thumb,
                      link: this.product.url_full,
                      price: this.product.price,
                      quantity: this.inventoryQuantity,
                    },
                  ],
                };

                const behavior = new iPORTO_ApplicationGears_Measure();
                behavior.add_to_cart(iPORTO_ApplicationGears_Measure_behavior_Attributes);
              }
            }
          }
          // * Fim da Automação iPORTO

          this.displayModal = true;
          this.productHasBeenBought = true;
          this.getCart();
          this.messageProcess = false;
        },
        (err) => {
          if (err.error.errors) {
            for (let error in err.error.errors) {
              this.messageService.add({ severity: 'error', detail: err.error.errors[error] });
              this.messageProcess = false;
              this.messageIsError = this.customerWishlistService.error.throw(error);
            }
          }
        }
      );
    }
  }

  /**
   * Clients marketplace wishlist product service create
   */
  clientMarketplaceWishlistProductServiceCreate(product: any) {
    this.customerWishlistService
      .store({
        client_marketplace_core_product_id: product.id,
      })
      .subscribe(
        (response: any) => {
          this.profile.customer.wishlist.push(response.data);
          this.messageService.add({ severity: 'success', detail: 'Produto adicionado a sua lista de desejos.' });
          /**
           * * Automação iPORTO
           */
          if (environment.production) {
            if (this.profile !== undefined) {
              if (this.profile.hasOwnProperty('email')) {
                var iPORTO_ApplicationGears_Measure_behavior_Attributes = {
                  id: '5ca37527-da8e-419c-8f9e-4a0e918448dd',
                  customer_application_contact: {
                    email: this.profile.email,
                  },

                  value: this.inventoryQuantity * this.product.price,
                  currency: 'BRL',

                  items: [
                    {
                      id: this.product.id,
                      title: this.product.name,
                      image: this.product.image_thumb,
                      link: this.product.url_full,
                      price: this.product.price,
                      quantity: this.inventoryQuantity,
                    },
                  ],
                };

                const behavior = new iPORTO_ApplicationGears_Measure();
                behavior.add_to_wishlist(iPORTO_ApplicationGears_Measure_behavior_Attributes);
              }
            }
          }
          // * Fim da Automação iPORTO
        },
        (err) => {
          if (err.error.errors) {
            for (let error in err.error.errors) {
              this.messageService.add({ severity: 'error', detail: err.error.errors[error] });
            }
          }
        }
      );
  }

  /**
   * Clients marketplace notice product service destroy
   */
  clientMarketplaceWishlistProductServiceDestroy(product: any) {
    let favorite: any;
    this.profile.customer.wishlist.find((fav: any) => {
      if (fav.client_marketplace_core_product_id === product.id) {
        favorite = fav;
      }
    });

    var _ = this.customerWishlistService.destroy(favorite);
    if (_ !== null) {
      _.subscribe(
        (response: any) => {
          const index = this.profile.customer.wishlist.indexOf(favorite);
          if (index !== -1) {
            this.profile.customer.wishlist.splice(index, 1);
            if (this.route == '/a' || this.route == '/a/wishlist') {
              this.customerWishlistServiceIndex.emit();
            }
          }
          this.messageService.add({ severity: 'info', detail: 'Produto removido da sua lista de desejos.' });
          if (this.route.includes('/wishlist')) {
            this.fncDataIndex.emit(response.data);
          }
        },
        (err) => {
          var errorThrow = this.customerWishlistService.error.throw(err);
          if (errorThrow.hasOwnProperty('errors')) {
            errorThrow.errors.forEach((erro) => {
              this.messageService.add({ severity: 'error', sticky: true, detail: erro });
            });
          }
        }
      );
    }
  }

  clientMarketplacePreferenceFavoriteServiceExists(product: any) {
    return this.profile?.customer.wishlist.find((favorite: any) => favorite.client_marketplace_core_product_id === product.id);
  }

  checkIfProductIsFavorite(product: any) {
    const isProductInWishlist = this.clientMarketplacePreferenceFavoriteServiceExists(product);
    if (!isProductInWishlist) {
      this.clientMarketplaceWishlistProductServiceCreate(product);
    } else {
      this.clientMarketplaceWishlistProductServiceDestroy(product);
    }
  }

  /**
   * Clients marketplace notice product service create
   */
  clientMarketplaceNoticeProductServiceCreate(id: any) {
    if (this.profile == undefined) {
      this.messageService.add({ severity: 'error', detail: 'Faça login em sua conta para criar um aviso.' });
    } else {
      var _ = this.customerNoticeService.store({
        client_marketplace_core_product_id: id,
      });
      if (_ !== null) {
        _.subscribe(
          (response: any) => {
            this.messageService.add({ severity: 'success', detail: 'Você receberá um e-mail quando o produto ' + this.product.name + ' estiver disponível' });
          },
          (err) => {
            var errorThrow = this.customerNoticeService.error.throw(err);
            if (errorThrow.hasOwnProperty('errors')) {
              errorThrow.errors.forEach((erro) => {
                this.messageService.add({ severity: 'error', sticky: true, detail: erro });
              });
            }
          }
        );
      }
    }
  }

  inventoryQuantity: any = 1;
  clientMarketplaceCartProductQuantityPlus() {
    this.inventoryQuantity++;
  }

  clientMarketplaceCartProductQuantityMinus() {
    this.inventoryQuantity--;
  }

  closeModal() {
    this.displayModal = false;
  }
}
