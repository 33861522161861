declare let gtag: Function;
declare let fbq: Function;
declare global {
  interface Window {
    VLibras: any;
  }
}
import { environment } from 'src/environments/environment';
import { Component, OnInit, OnDestroy, Renderer2, RendererFactory2, HostListener } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, RoutesRecognized, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
// [[Providers]]
import { CustomerUserService } from 'src/project/services/panel/client/marketplace/customer/user/user.service';
import { CollectiveMarketplaceService } from '~services/collective/client/marketplace/marketplace.service';
import { CollectiveLeadService } from '~services/collective/client/marketplace/lead/lead.service';
import { ClientMarketplaceCoreCategoryService } from '~services/collective/client/marketplace/core/category/category.service';
import { CollectiveMarketplaceCartService } from '~services/collective/client/marketplace/cart/cart.service';
import { CollectiveMarketplaceCoreVisualBannerService } from '~services/collective/client/marketplace/core/visual/banner/banner.service';
import { ClientMarketplaceCoreProductService } from '~project/services/collective/client/marketplace/core/product/product.service';
// ]
// [[Interfaces do Componente]]
// ]
@Component({
  selector: 'app-panel-footer',
  templateUrl: './component.html',
  styleUrls: ['./component.css'],
})
export class WwwFooterComponent implements OnDestroy {
  /**
   * Base de uso em Component
   */
  public environment = environment;
  messageProcess = false;
  messageIsError: any;
  profile: any;
  marketplace: any = null;
  cart: any = null;
  banners: any = null;
  banners_fnc_cta: any = null;
  fullBannerFooterBanners: [];
  subscribedMethods: any = [];
  showSuggestions = false;
  searchHistory = [];
  selectedCategory: string = 'Cleaning';
  autoplay: boolean = false;
  infinite: boolean = false;
  navigationSubscription: any;

  private renderer: Renderer2;

  constructor(
    /**
     * Serviços da Aplicação
     */
    private router: Router,
    private rendererFactory: RendererFactory2,
    /**
     * Base de uso em Component
     */
    private userService: CustomerUserService,
    private collectiveMarketplaceService: CollectiveMarketplaceService,
    private collectiveMarketplaceCartService: CollectiveMarketplaceCartService,
    private collectiveMarketplaceCoreVisualBannerService: CollectiveMarketplaceCoreVisualBannerService,
    /**
     * Services
     */
    private collectiveLeadService: CollectiveLeadService,
    private clientMarketplaceCoreCategoryService: ClientMarketplaceCoreCategoryService,
    private clientMarketplaceCoreProductService: ClientMarketplaceCoreProductService,
    private messageService: MessageService
  ) {
    if (localStorage.getItem('boxPriv') != null) {
      this.boxPriv = false;
    }

    this.renderer = rendererFactory.createRenderer(null, null);

    this.clientMarketplaceCoreProductServiceIndexChild();

    this.actualUrl = this.router.url;
  }

  actualUrl: any;
  currentRoute: string = '';
  iconColor: string;
  ngOnInit(): void {
    this.ipBuildEcommerceDependencies();

    this.router.events.subscribe((event) => {
      if (this.currentRoute == '') {
        if (event instanceof NavigationStart || event instanceof NavigationEnd || event instanceof RoutesRecognized) {
          this.currentRoute = event.url;
          //
          if (this.marketplace != null) {
            if (this.marketplace.analytic != null) {
              if (this.marketplace.analytic.google != null) {
                // EVENTO DE VISUALIZAÇÃO DE: GOOGLE ANALYTICS
                // GA4 Enhanced Measurement: gtm.historyChange-v2
                gtag('event', 'gtm.historyChange-v2', {
                  page_path: event.url,
                  send_to: this.marketplace.analytic.google.gtag,
                });
                gtag('event', 'page_view', {
                  page_path: event.url,
                  send_to: this.marketplace.analytic.google.gtag,
                });

                // EVENTO.
                // https://developers.google.com/tag-platform/gtagjs/reference/events?hl=pt-br
                // https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce
                // https://web.facebook.com/business/help/402791146561655?id=1205376682832142&_rdc=1&_rdr
                /**
                 *
                 */
                if (this.strstr(event.url, '/a', true) !== false) {
                  gtag('event', 'generate_lead', {
                    event_label: 'Novo Lead',
                    event_category: 'generate_lead',
                    value: 0,
                    send_to: this.marketplace.analytic.google.gtag,
                  });
                }
                /**
                 */
                if (this.strstr(event.url, '/cart', true) !== false) {
                  // Google
                  gtag('event', 'add_to_cart', {
                    event_label: 'Adicionado ao Carrinho',
                    event_category: 'add_to_cart',
                    value: 0,
                    send_to: this.marketplace.analytic.google.gtag,
                  });
                  // FaceBook
                  fbq('track', 'AddToCart');
                }
                /**
                 *
                 */
                if (this.strstr(event.url, '/checkout', true) !== false) {
                  // Google
                  gtag('event', 'begin_checkout', {
                    event_label: 'Iniciado Checkout',
                    event_category: 'begin_checkout',
                    value: 0,
                    send_to: this.marketplace.analytic.google.gtag,
                  });
                  // FaceBook
                  fbq('track', 'InitiateCheckout');
                }
              }
            }
          }
        }
      }
    });
  }

  isVisible: boolean = false;
  showCloseButton: boolean = false;

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent): void {
    if (this.actualUrl.includes('/blog/') == true) {
      this.checkVisibility();
      if (event.clientY <= 5 && this.isVisible == false) {
        this.setFixedFooter();
      }
    }
  }

  setFixedFooter() {
    const formElement = document.getElementById('form');
    if (formElement) {
      this.showCloseButton = true;
      formElement.classList.add('fixed-footer');
    }
  }

  closeForm() {
    const formElement = document.getElementById('form');
    if (formElement) {
      this.showCloseButton = false;
      formElement.classList.remove('fixed-footer');
    }
  }

  checkVisibility() {
    const formElement = document.getElementById('form');
    if (formElement) {
      const rect = formElement.getBoundingClientRect();
      if (rect.top >= window.innerHeight || rect.bottom <= 0) {
        this.isVisible = false;
      } else {
        this.isVisible = true;
      }
    }
  }

  /**
   * build ecommerce dependencies
   */
  ipBuildEcommerceDependencies() {
    /**
     * Base
     *
     * Request da base de uso.
     */
    // PROFILE
    this.userService.getUser().subscribe((Profile: any) => {
      if (Profile != null) {
        if (JSON.parse(Profile) != null) {
          this.profile = JSON.parse(Profile);
        }
      }
    });
    // MARKETPLACE
    this.collectiveMarketplaceService.getMarketplace().subscribe((Marketplace: any) => {
      if (Marketplace != null) {
        if (JSON.parse(Marketplace) != null) {
          this.marketplace = JSON.parse(Marketplace);
          /**
           *
           * CONFIGURAÇÕES ADICIONAIS DESTE `COMPONENTE`
           *
           */
          this.tagAccessibility();
          this.tagAnalytics();
        }
      }
    });
    // CART
    this.collectiveMarketplaceCartService.getCart().subscribe((Cart: any) => {
      if (Cart != null) {
        if (JSON.parse(Cart) != null) {
          this.cart = JSON.parse(Cart);
        }
      }
    });
    // BANNERS
    this.banners_fnc_cta = function (banner: any) {
      if (banner.url != '') {
        document.location = banner.url;
      }
      return false;
    };
    this.collectiveMarketplaceCoreVisualBannerService.getBanners().subscribe((Data: any) => {
      if (Data != null) {
        if (JSON.parse(Data) != null) {
          this.banners = JSON.parse(Data);

          let bannersCopy = this.banners.slice();
          this.fullBannerFooterBanners = bannersCopy.filter((banner) => banner.core_visual_banner_area === 'full_banner_footer');

          if (this.fullBannerFooterBanners.length > 1) {
            this.autoplay = true;
            this.infinite = true;
          }
        }
      }
    });

    // # /*
    // # |--------------------------------------------------------------------------
    // # | Subscribers: Search
    // # |--------------------------------------------------------------------------
    // # |
    // # */
    var _ = this.clientMarketplaceCoreProductService.getSearch().subscribe((searchReplaySubject: any) => {
      this.searchParams.query = searchReplaySubject.query;
      this.clientMarketplaceCoreProductService.search(searchReplaySubject).subscribe((searchResults: any) => {
        this.clientMarketplaceCoreProductService.setSearchResult(searchResults);
      });
    });
    this.subscribedMethods.push(_);

    var _ = this.clientMarketplaceCoreProductService.getSearchResult().subscribe((searchResults: any) => {
      this.searchResults = searchResults;
    });
    this.subscribedMethods.push(_);
  }

  /**
   * after view init
   */
  ngAfterViewInit() {}

  /**
   * on destroy
   */
  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  drawerOpen = false;

  toggleDrawer() {
    this.drawerOpen = !this.drawerOpen;
  }

  slideConfig = {
    slidesToShow: 1,
    dots: false,
    infinite: this.infinite,
    autoplay: true,
    arrows: false,
    adaptiveHeight: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  searchParams: any = {
    per_page: 12,
    page: 1,
    client_marketplace_id: 1,
    query: '',
    start_at: new Date(),
    highlight_pre_tag: '<strong style="color: #831843;">',
    highlight_post_tag: '</strong>',
  };
  searchResults: any;
  clientMarketplaceCoreProductServiceIndex() {
    this.clientMarketplaceCoreProductService.setSearch(this.searchParams);
    this.showSuggestions = true;
  }

  clientMarketplaceCoreProductServiceClear() {
    if (this.searchParams.query !== '') {
      this.searchParams.query = '';
      this.clientMarketplaceCoreProductServiceIndex();
    } else {
      this.drawerOpen = false;
    }
  }

  clientMarketplaceCoreProductServiceRedirectSearch() {
    if (this.searchParams.query != '' && typeof this.searchParams.query !== 'undefined') {
      this.router.navigate(['/s/query/' + this.searchParams.query.replace(/\s+/g, '-')]);
      this.searchHistory = this.searchHistory || [];
      var search = {
        name: this.searchParams.query,
        url: '/s/query/' + this.searchParams.query.replace(/\s+/g, '-'),
      };
      this.searchHistory.push(search);
      localStorage.setItem('search_history', JSON.stringify(this.searchHistory));
    } else {
      this.router.navigate(['/s']);
    }
  }

  onFocus() {
    if (this.searchHistory.length > 0 || this.searchResults?.data || this.searchParams.query !== '') {
      this.showSuggestions = true;
    } else if (this.searchParams.query == '' && this.searchResults?.data) {
      this.searchResults = '';
    }
  }

  onBlur() {
    setTimeout(() => {
      this.showSuggestions = false;
    }, 200); // Timeout para permitir clicar nas sugestões
  }

  onClearSearchHistory() {
    this.searchHistory = [];
    localStorage.removeItem('search_history');
  }

  onClearSearchItem(data) {
    var i = this.searchHistory.indexOf(data);
    if (i >= 0) {
      this.searchHistory.splice(i, 1);
      localStorage.setItem('search_history', JSON.stringify(this.searchHistory));
      this.searchHistory = JSON.parse(localStorage.getItem('search_history'));
    }
  }

  onChangeCategoryView(data: string) {
    this.selectedCategory = data;
  }

  lead: any = {
    name: '',
    email: '',
  };
  /**
   * Collectives lead service store
   */
  collectiveLeadServiceStore() {
    let _ = this.collectiveLeadService.store(this.lead);
    if (_ !== null) {
      _.subscribe(
        (res) => {
          let response: any = res;
          this.messageService.add({ severity: 'success', summary: 'Quase Lá', detail: 'Acesse seu e-mail para confirmar seu cadastro.' });
        },
        (error: any) => {
          this.collectiveLeadService.error.throw(error);
          this.messageService.add({ severity: 'info', summary: '', detail: error.error.errors.exception });
        }
      );
    }
  }

  boxPriv: any = true;
  /**
   * close privacy
   */
  fnClosePrivacy() {
    localStorage.setItem('boxPriv', 'no');
    this.boxPriv = false;
  }

  categories: any;
  /**
   * Clients marketplace core product service index child
   */
  clientMarketplaceCoreProductServiceIndexChild() {
    var _ = this.clientMarketplaceCoreCategoryService.index_child({ per_page: 100 });
    if (_ !== null) {
      _.subscribe(
        (response: any) => {
          this.categories = response.data;
        },
        (error) => {}
      );
    }
  }

  accessibility: any = false;

  /**
   * Tags accessibility
   */
  tagAccessibility() {
    // ADICIONA: SRC PARA SCRIPT
    const s = document.createElement('script');
    s.defer = true;
    s.src = '//vlibras.gov.br/app/vlibras-plugin.js';
    s.onload = function () {
      let VLibras = new window.VLibras.Widget('//vlibras.gov.br/app');
    };
    this.renderer.appendChild(document.body, s);
  }

  /**
   * Tags analytics
   */
  tagAnalytics() {
    /**
     *
     */
    // Google.
    if (this.marketplace.analytic.google != null) {
      this.tagGoogleAnalytics(this.marketplace.analytic.google.gtag);
      this.tagGoogleTagManager(this.marketplace.analytic.google.gtag_tm);
      this.tagGoogleAdwords(this.marketplace.analytic.google.gtag_ad);
    }
    /**
     *
     */
    // Facebook: Pixel
    if (this.marketplace.analytic.facebook != null) {
      this.tagFacebook(this.marketplace.analytic.facebook.ftag);
    }
  }

  /**
   * Tags google analytics
   * @param e
   */
  tagGoogleAnalytics(e: string) {
    if (e != null) {
      if (document.getElementById(e) == null) {
        // ADICIONA: SRC PARA SCRIPT
        const s = document.createElement('script');
        s.setAttribute('id', e);
        //s.async = true;
        s.src = 'https://www.googletagmanager.com/gtag/js?id=' + e;
        document.head.append(s);
        gtag('js', new Date());
        gtag('config', e, {
          send_page_view: false,
        });
      }
    }
  }

  /**
   * Tags google tag manager
   * @param e
   */
  tagGoogleTagManager(e: string) {
    if (e != null) {
      if (document.getElementById(e) == null) {
        // ADICIONA: SRC PARA SCRIPT
        const s = document.createElement('script');
        s.setAttribute('id', e);
        s.async = true;
        s.src = 'https://www.googletagmanager.com/gtm.js?id=' + e;
        document.head.append(s);

        const x = document.createElement('iframe');
        x.src = 'https://www.googletagmanager.com/ns.html?id=' + e;
        document.body.append(x);
      }
    }
  }

  /**
   * Tags google adwords
   * @param e
   */
  tagGoogleAdwords(e: string) {
    if (e != null) {
      if (document.getElementById(e) == null) {
        // ADICIONA: SRC PARA SCRIPT
        const s = document.createElement('script');
        s.setAttribute('id', e);
        s.async = true;
        s.src = 'https://www.googletagmanager.com/gtag/js?id=' + e;
        document.head.append(s);
      }
    }
  }

  /**
   * Tags facebook
   * @param e
   */
  tagFacebook(e: string) {
    if (e != null) {
      if (document.getElementById(e) == null) {
        fbq('init', e);
        fbq('track', 'PageView');
      }
    }
  }

  /**
   * Strstrs www footer component
   * @param haystack
   * @param needle
   * @param bool
   * @returns
   */
  strstr(haystack: any, needle: any, bool: boolean) {
    // Finds first occurrence of a string within another
    //
    // version: 1103.1210
    // discuss at: http://phpjs.org/functions/strstr    // +   original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
    // +   bugfixed by: Onno Marsman
    // +   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
    // *     example 1: strstr(‘Kevin van Zonneveld’, ‘van’);
    // *     returns 1: ‘van Zonneveld’    // *     example 2: strstr(‘Kevin van Zonneveld’, ‘van’, true);
    // *     returns 2: ‘Kevin ‘
    // *     example 3: strstr(‘name@example.com’, ‘@’);
    // *     returns 3: ‘@example.com’
    // *     example 4: strstr(‘name@example.com’, ‘@’, true);    // *     returns 4: ‘name’
    var pos = 0;

    haystack += '';
    pos = haystack.indexOf(needle);
    if (pos == -1) {
      return false;
    } else {
      if (bool) {
        return haystack.substr(0, pos);
      } else {
        return haystack.slice(pos);
      }
    }
  }
}
