import { environment } from "src/environments/environment";
// import "rxjs/add/operator/toPromise";
// import "rxjs/add/operator/catch";
// import "rxjs/add/observable/throw";
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
// [[Providers]]
import { ErrorService } from "~services/error.service";
// ]
@Injectable()
export class ClientMarketplaceCartProductService {
  authenticated_user: any;
  authenticated_cart: any;

  constructor(private router: Router, public http: HttpClient, public error: ErrorService) {
    this.authenticated_user = JSON.parse(localStorage.getItem("client_marketplace_customer_user"));
    this.authenticated_cart = JSON.parse(localStorage.getItem("client_marketplace_cart"));
  }

  /**
   *
   * @param data
   */
  getQuerystring(data: any) {
    let querystring: any = "";
    for (let entry in data) {
      querystring += entry + "=" + encodeURIComponent(data[entry]) + "&";
    }
    return querystring;
  }

  /**
   *
   * @param data
   */
  getPost(data: any) {
    let postData: any = {};
    for (let entry in data) {
      if (typeof data[entry] == "object") {
        if (data[entry] == null) {
          postData[entry] = null;
        } else {
          postData[entry] = JSON.stringify(data[entry]);
        }
      } else {
        postData[entry] = data[entry];
      }
    }
    return postData;
  }

  /**
   *
   * @param data
   */
  store(data: any) {
    if (this.authenticated_user != null) {
      data.client_marketplace_customer_id = this.authenticated_user.client_marketplace_customer_id;
    }
    let postData = this.getPost(data);

    let request = this.http.post(
      environment.api_url + "collective/client/marketplace/" + environment.marketplace.hostname + "/cart/" + data.client_marketplace_cart_uid + "/product",
      postData,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return request;
  }

  /**
   *
   * @param data
   */
  update(data: any) {
    if (this.authenticated_user != null) {
      data.client_marketplace_customer_id = this.authenticated_user.client_marketplace_customer_id;
    }
    let postData = this.getPost(data);

    let request = this.http.put(
      environment.api_url +
        "collective/client/marketplace/" +
        environment.marketplace.hostname +
        "/cart/" +
        data.client_marketplace_cart_uid +
        "/product/" +
        data.client_marketplace_core_product_id,
      postData,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return request;
  }

  /**
   *
   * @param data
   */
  destroy(data: any) {
    let request = this.http.delete(
      environment.api_url +
        "collective/client/marketplace/" +
        environment.marketplace.hostname +
        "/cart/" +
        data.client_marketplace_cart_uid +
        "/product/" +
        data.client_marketplace_core_product_id,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return request;
  }
}
