import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
// [[Modulos da Aplicação]]

// []
// [[Componentes/Rotas da Aplicação]]
import { UseHtmlPipe } from "./html.pipe";
// []
@NgModule({
  imports: [CommonModule],
  declarations: [UseHtmlPipe],
  providers: [],
  exports: [UseHtmlPipe],
})
export class UseHtmlPipeModule {}
