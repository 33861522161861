import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
// import { ToastrService } from "ngx-toastr";
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
// [[Providers]]
import { CustomerUserService } from '~services/panel/client/marketplace/customer/user/user.service';
// ]

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private userService: CustomerUserService) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.userService.profile().pipe(
      map((Profile: any) => {
        /**
         * * setUser() para utilizar em outros Componentes.
         */
        // TODO: Remover. Uso aplicado ao Header.
        // this.userService.setUser(Profile.data);

        if (Profile) {
          return true;
        }

        // this.toastrService.error("Você não está logado.", "Não é possível acessar esta área. Atualize sua página.", {
        //   positionClass: "toast-top-left",
        // });

        localStorage.removeItem('customer_user_user');
        this.router.navigate(['/b']);
        return false;
      }),
      catchError(() => {
        localStorage.removeItem('customer_user_user');
        this.router.navigate(['/b']);
        return of(false);
      })
    );
  }
}
