<div class="flex flex-col border border-gray-100 rounded-lg p-3.5 h-full w-full lg:w-52 xl:w-full hover:border-pink-800">
  <div class="flex-1">
    <!-- promocao e fav -->
    <div class="flex justify-end items-center" [ngClass]="{'justify-between': product.price_discount_percent != null}">
      <div *ngIf="product.price_discount_percent != null" class="bg-[#FFBE0B] rounded-md p-1.5">
        <p class="text-black text-xs lg:font-medium">{{product.price_discount_percent | number:'1.0-0'}}%</p>
      </div>

      <div>
        <button title="Favoritar" (click)="checkIfProductIsFavorite(product)" class="hover:opacity-80 cursor-pointer">
          <ng-container *ngIf="clientMarketplacePreferenceFavoriteServiceExists(product); else notCustomerFavorite">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 stroke-transparent text-pink-800">
              <path
                d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z"
              />
            </svg>
          </ng-container>
          <ng-template #notCustomerFavorite>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 stroke-gray-600">
              <path stroke-linecap="round" stroke-linejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z" />
            </svg>
          </ng-template>
        </button>
      </div>
    </div>

    <!-- foto e estrelas -->
    <div>
      <a [routerLink]="['/p/'+product.url_handle+'/id/'+product.id]">
        @if(product.image_thumb != ''){
        <div class="flex justify-center">
          <img class="relative bg-white mb-5 overflow-hidden h-36 w-40 object-contain lg:max-w-[75%] lg:h-44" loading="lazy" src="{{product.image_thumb}}" alt="{{product.name}}" />
        </div>
        }
        <div class="flex justify-center">
          @if(product.image_thumb == ''){
          <img class="relative bg-white mb-5 overflow-hidden h-36 w-40 lg:max-w-[75%] lg:h-44" loading="lazy" src="/assets/images/no_imagem.png" alt="{{product.name}}" />
          }
        </div>
      </a>
    </div>

    <!-- detalhes e carrinho -->

    <div class="mt-2">
      <p class="text-[10px] text-gray-700 tracking-widest uppercase font-medium md:text-xs">{{product.category}}</p>
    </div>
    <a class="mt-3 w-40" [routerLink]="['/p/'+product.url_handle+'/id/'+product.id]">
      <p class="text-gray-900 font-semibold leading-5 text-sm md:text-base">{{product.name}}</p>
    </a>
    @if(product.stars > 0) {
    <div class="my-2">
      <img class="w-16" src="/assets/images/star{{product.stars}}.png" alt="{{product.stars}} Estrelas de Avaliação" />
    </div>
    } @if(product.inventory_quantity > 0 && product.product_status == 'active'){
    <div class="mt-2 flex flex-col items-start lg:space-x-0">
      @if(product.price_compare_at > product.price){
      <p class="text-xs text-gray-700 line-through sm:text-sm font-medium">R$ {{product.price_compare_at | number:'1.2-2'}}</p>
      }
      <p class="text-pink-800 font-bold md:text-lg">R$ {{product.price | number:'1.2-2'}}</p>
    </div>

    @if(product.price_installment_no_fee > 0){
    <p class="text-xs text-gray-800"><span class="font-bold">{{product.installment_no_fee}}x</span> de <span class="font-bold">R$ {{product.price_installment_no_fee | number:'1.2-2'}}</span> sem juros*</p>
    } }
  </div>
  @if(((inventoryQuantity <= product.inventory_quantity) && !messageProcess) && product.product_status == 'active' ){
  <button (click)="clientMarketplaceCartProductServiceCreate(product)" class="bg-[#FFBE0B] rounded-lg mt-4 hover:bg-amber-500">
    <p class="text-black p-2 text-center text-sm font-medium flex items-center gap-x-1.5 justify-center">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="h-4 w-4">
        <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
      </svg>
      Comprar
    </p>
  </button>
  } @if(product.inventory_quantity < 1 || product.product_status == 'inactive'){
  <button title="Avise-me quando disponível" (click)="clientMarketplaceNoticeProductServiceCreate(product.id);" class="border border-pink-800 text-pink-800 rounded-lg mt-2 hover:bg-pink-800 hover:text-white">
    <p class="px-3 py-2 font-medium text-center text-sm">Avise-me quando disponível</p>
  </button>
  } @if(messageProcess){
  <button title="Carregando" disabled type="button" class="mt-4 w-full text-white bg-pink-900 hover:bg-pink-900 focus:ring-4 focus:ring-pink-300 font-medium rounded-lg text-sm p-2 text-center me-2 inline-flex items-center justify-center">
    <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="#E5E7EB"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="currentColor"
      />
    </svg>
    Carregando...
  </button>
  }
</div>

<!-- Backdrop -->
<div [ngClass]="{'hidden': !displayModal, 'fixed inset-0 bg-black opacity-50 z-50': displayModal}" (click)="displayModal == false"></div>

@if(cart?.client_marketplace_cart_uid && productAddedToCart?.id){
<!-- drawer component -->
<div [ngClass]="{'hidden': !displayModal}" class="font-heading mx-auto fixed bottom-0 top-1/4 left-0 h-fit right-0 z-50 w-96 transform-none overflow-y-auto bg-white px-4 py-6 transition-transform">
  <div class="relative p-5 flex flex-col justify-center items-center">
    <div class="flex flex-col items-center justify-center">
      <div class="mb-5 flex h-10 w-10 items-center justify-center rounded-full bg-green-100 text-green-500">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6 lg:h-8 lg:w-8">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      </div>
      <div class="text-center">
        <h3 class="text-lg font-semibold text-secondary-900">Produto Adicionado ao Carrinho</h3>
        <div class="mt-2 text-sm text-secondary-500">{{inventoryQuantity}} x {{product.name}}</div>
      </div>
    </div>
    <div class="mt-5 flex justify-end gap-3">
      <button
        [routerLink]="['/p/cart/'+cart.client_marketplace_cart_uid+'/shipping']"
        [queryParams]="{one_click_buy: true}"
        type="button"
        class="flex-1 rounded-lg border border-pink-800 bg-white px-4 py-2 text-center text-sm font-medium text-pink-800 shadow-sm transition-all hover:bg-gray-100 focus:ring focus:ring-gray-100 disabled:cursor-not-allowed disabled:border-gray-100 disabled:bg-gray-50 disabled:text-gray-400"
      >
        Finalizar Compra
      </button>
      <button
        [routerLink]="['/p/cart/'+cart.client_marketplace_cart_uid]"
        type="button"
        class="flex-1 rounded-lg border border-pink-800 bg-pink-800 px-4 py-2 text-center text-sm font-medium text-white shadow-sm transition-all hover:border-pink-900 hover:bg-pink-900 focus:ring focus:ring-pink-900 disabled:cursor-not-allowed disabled:border-gray-300 disabled:bg-gray-500"
      >
        Carrinho
      </button>
    </div>
    <div class="mt-5 flex justify-center">
      <button (click)="closeModal()" type="button" class="flex-1 rounded-lg py-2 text-center text-sm font-medium text-pink-800 transition-all hover:bg-gray-100 focus:ring focus:ring-gray-100 disabled:cursor-not-allowed disabled:border-gray-100 disabled:bg-gray-50 disabled:text-gray-400">Continuar comprando</button>
    </div>
  </div>
</div>
}
