import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// [[Modulos da Aplicação]]

// []
// [[Componentes/Rotas da Aplicação]]
import { ReplaceEmWithStrongPipe } from './replaceEmWithStrong.pipe';
// []
@NgModule({
  imports: [CommonModule],
  declarations: [ReplaceEmWithStrongPipe],
  providers: [],
  exports: [ReplaceEmWithStrongPipe],
})
export class ReplaceEmWithStrongPipeModule {}
