import { Component, AfterViewInit, Input, Renderer2, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'embedForm',
  template: '<div #formContainer id="iPORTO_Application_Form"></div>',
})
export class EmbedFormComponent implements AfterViewInit {
  @Input() form_id: string;
  @ViewChild('formContainer', { static: false }) formContainer!: ElementRef;

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit() {
    // Adiciona um pequeno delay para garantir a execução após a renderização
    setTimeout(() => {
      this.loadScript();
    }, 0);
  }

  loadScript() {
    const iPORTO_Application_Form_Style = this.renderer.createElement('script');
    iPORTO_Application_Form_Style.text = `
      var iPORTO_Application_Form_Style_Height = '300px'
      var iPORTO_Application_Form_Style =
        "#embed-application-form-container {background-color: #F3F4F6}" +
        "#embed-application-form-head h2 { color: #8C2A48 }" +
        "#embed-application-form {background-color: #F3F4F6; width: 580px; padding: 1px 0; align-items: center; flex-direction: row;}" +
        "#embed-application-form button {background-color: #8C2A48}" +
        "#embed-application-form-fields {flex-direction: row;}" +
        ".embed-application-form-field {padding: 5px;}" +
        ".embed-application-form-field-label {display: block}";
    `;

    const iPORTO_Application_Form = this.renderer.createElement('script');
    iPORTO_Application_Form.src = 'https://embed.iporto.com.br/embed/application/integration/form/' + this.form_id + '/javascript';
    iPORTO_Application_Form.async = true;
    iPORTO_Application_Form.defer = true;

    this.renderer.appendChild(this.formContainer.nativeElement.parentNode, iPORTO_Application_Form_Style);
    this.renderer.appendChild(this.formContainer.nativeElement.parentNode, iPORTO_Application_Form);
  }
}
