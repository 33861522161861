import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
// [[PrimeNG]]
import { ToastModule } from "primeng/toast";
import { DialogModule } from "primeng/dialog";
import { ProgressSpinnerModule } from "primeng/progressspinner";
// []
// [[Providers/Services da Aplicação]]
import { ClientMarketplaceCartProductService } from "~services/collective/client/marketplace/cart/product/product.service";
import { CustomerWishlistService } from "~services/panel/client/marketplace/customer/wishlist/wishlist.service";
import { CustomerNoticeService } from "~services/panel/client/marketplace/customer/notice/notice.service";
// []
// [[Componentes/Rotas da Aplicação]]
import { FirstProductContainerComponent } from "./component";
// []
@NgModule({
  imports: [
    //
    CommonModule,
    RouterModule,
    //[[PrimeNg]]
    ToastModule,
    DialogModule,
    ProgressSpinnerModule,
  ],
  declarations: [FirstProductContainerComponent],
  providers: [ClientMarketplaceCartProductService, CustomerWishlistService, CustomerNoticeService],
  exports: [FirstProductContainerComponent],
})
export class IncludeProductContainerModule {}
