import { environment } from 'src/environments/environment';

import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// [[Providers]]
import { ErrorService } from '~services/error.service';
import { CacheService } from '~services/cache.service';
// ]
@Injectable()
export class CoreLocalizationZipcodeService {
  constructor(private router: Router, public http: HttpClient, public error: ErrorService, public cacheService: CacheService) {}

  private zipcodeSubject = new BehaviorSubject<any | null>(this.getInitialZipcode());

  zipcode$ = this.zipcodeSubject.asObservable();

  private getInitialZipcode(): any | null {
    const storedZipcode = localStorage.getItem('header_address');
    try {
      // Tente fazer o parse do valor, mas retorne null se não for JSON válido
      return storedZipcode ? JSON.parse(storedZipcode) : null;
    } catch (error) {
      console.error('Failed to parse stored zipcode:', error);
      return null; // Em caso de erro, retorne null
    }
  }

  setZipcode(zipcode: string) {
    localStorage.setItem('header_address', JSON.stringify(zipcode)); // Atualiza o valor no localStorage.
    this.zipcodeSubject.next(zipcode); // Atualiza o valor no estado.
  }

  getZipcode(): Observable<string> {
    return this.zipcodeSubject.asObservable();
  }

  /**
   *
   * @param data
   */
  getQuerystring(data: any) {
    let querystring: any = '';
    for (let entry in data) {
      querystring += entry + '=' + encodeURIComponent(data[entry]) + '&';
    }
    return querystring;
  }

  /**
   *
   * @param data
   */
  getPost(data: any) {
    let postData: any = {};
    for (let entry in data) {
      if (typeof data[entry] == 'object') {
        if (data[entry] == null) {
          postData[entry] = null;
        } else {
          postData[entry] = JSON.stringify(data[entry]);
        }
      } else {
        postData[entry] = data[entry];
      }
    }
    return postData;
  }

  /**
   *
   * @param data
   */
  view(data: any) {
    let querystring = this.getQuerystring(data);

    let request = this.http.get(environment.api_url + 'collective/core/localization/zipcode/' + data.zipcode + '?' + querystring, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return request;
  }
}
