@if(!actualUrl.includes('/social')){
<p-toast position="center"></p-toast>
<div id="desktop_bar" class="block z-30">
  <header class="font-heading bg-pink-900">
    <div id="banner_top_header">
      <ngx-slick-carousel class="hidden lg:block carousel overflow-x-hidden overflow-y-hidden justify-center w-full" #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem class="slide" *ngFor="let banner of fullBannerTopHead; trackBy: trackByUrl">
          <img src="{{ banner.url_image }}" alt="" width="100%" height="100%" class="object-cover" />
        </div>
      </ngx-slick-carousel>
      <ngx-slick-carousel class="block lg:hidden carousel overflow-x-hidden overflow-y-hidden justify-center w-full" #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem class="slide" *ngFor="let banner of fullBannerTopHead; trackBy: trackByUrl">
          <img src="{{ banner.url_image_mobile }}" alt="" width="100%" height="100%" class="object-cover" />
        </div>
      </ngx-slick-carousel>
    </div>
    <nav class="relative mx-auto flex max-w-7xl items-center lg:items-end lg:justify-between gap-x-4 py-4 px-2 w-full lg:px-8 lg:gap-x-6 lg:py-6" aria-label="Global">
      <div class="flex items-end gap-x-10 w-full">
        <a href="#" id="logo">
          <span class="sr-only">Casa de Gê</span>
          <img class="block max-w-[120px] lg:max-w-full lg:w-[180px]" src="/assets/themes/{{environment.marketplace.domainObject.sld}}/images/cdg-logo-branco (1).png" alt="Logo {{marketplace?.name}}" />
        </a>
        <div class="flex flex-col gap-y-4 w-full">
          <div class="hidden lg:flex items-center gap-x-6 text-white">
            <a href="https://wa.me/+5511966294417" class="flex items-center gap-x-1.5" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" id="IconChangeColor" height="20" width="20">
                <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                <path
                  d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"
                  id="mainIconPathAttribute"
                  fill="#ffffff"
                ></path>
              </svg>
              <p class="text-sm tracking-wide hover:underline">Casa de Gê no Zap</p>
            </a>
            <p class="text-sm tracking-wide">Compra 100% segura</p>
            <!-- <a href="javascript:void(0)" (click)="accessibility = !accessibility;" class="link_footer"> <fa-icon [icon]="['fas', 'sign-language']" size="2x"></fa-icon> Accessibilidade </a> -->
          </div>

          <div class="w-full lg:flex lg:justify-end lg:gap-x-8 lg:min-w-[80%]">
            <form method="POST" (ngSubmit)="clientMarketplaceCoreProductServiceRedirectSearch();" class="relative flex min-w-[200px] items-center w-full lg:min-w-0">
              <label for="search" class="sr-only">Buscar</label>
              <div class="relative w-full">
                <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg class="w-4 h-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                  </svg>
                </div>
                <input
                  [(ngModel)]="search.query"
                  #search.query="ngModel"
                  (keyup)="clientMarketplaceCoreProductServiceIndex()"
                  type="text"
                  class="bg-gray-50 lg:bg-white border border-gray-300 text-gray-900 text-xs md:text-sm rounded-lg focus:ring-pink-500 focus:border-pink-500 block w-full ps-8 truncate"
                  placeholder="O que você procura hoje?"
                  required
                  (focus)="onFocus()"
                  (blur)="onBlur()"
                />
                <button title="Limpar busca" (click)="clearSearch()" type="button" class="absolute inset-y-0 end-0 flex items-center pe-3">
                  <svg class="w-4 h-4 text-gray-500 hover:text-gray-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              @if((searchHistory?.length > 0 || (searchResults?.data && search.query !== '')) && showSuggestions){
              <div class="absolute z-20 left-1/2 transform -translate-x-1/2 top-12 space-y-5 p-4 pt-4 w-full bg-white shadow rounded-md">
                @if(searchHistory?.length > 0 && search.query == ''){
                <div class="flex items-center justify-between">
                  <h1 class="text-gray-800 font-medium">Pesquisas recentes</h1>
                  <button (click)="onClearSearchHistory()" type="button" title="Deletar histórico de busca" class="text-pink-900 text-sm font-medium">Limpar</button>
                </div>
                <div class="flex flex-col text-pink-900 font-semibold text-sm divide-y divide-gray-100">
                  <div *ngFor="let search of searchHistory" class="flex items-center justify-between py-3">
                    <a [routerLink]="[search.url]"> {{search.name}} </a>
                    <button (click)="onClearSearchItem(search)">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 text-gray-800">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                </div>
                } @else if(searchResults?.data && search.query !== '') {
                <ng-container *ngFor="let result of searchResults?.data; let index = index;">
                  <ng-container *ngIf="index < 4">
                    <div class="flex flex-col my-1">
                      <a [href]="result.url" class="font-medium text-sm text-gray-900 hover:text-pink-900" [innerHTML]="result._formatted.name | html"> </a>
                    </div>
                  </ng-container>
                </ng-container>
                }
              </div>
              }
            </form>

            <div (mouseenter)="togglePopup('cep', true)" (mouseleave)="togglePopup('cep', false)" class="hidden lg:inline-flex relative">
              <button type="button" title="Adicionar CEP" class="flex gap-x-0.5 text-white items-end text-sm leading-4">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                </svg>
                <div class="flex flex-col items-start gap-y-0.5">
                  <p class="text-white text-xs">{{headerAddress !== null || headerAddress !== undefined ? "Receber em:" : ""}}</p>
                  <p class="text-left text-sm whitespace-nowrap font-semibold">{{headerAddress !== undefined ? (headerAddress.zipcode | zipcode) + ' - ' + headerAddress.city : "Informe seu CEP"}}</p>
                </div>
              </button>
              <div *ngIf="showCepPopup" class="absolute right-0 top-10 bg-white border border-gray-200 rounded-md p-4 shadow-lg mt-2 w-72 z-20" (mouseenter)="togglePopup('cep', true)" (mouseleave)="togglePopup('cep', false)">
                <h5 id="drawer-bottom-label" class="mb-4 leading-5 inline-flex items-center text-base font-semibold text-pink-900">Escolha onde deseja receber suas compras</h5>

                <p class="mb-6 max-w-lg text-sm text-gray-500">Tenha informações detalhadas sobre valores e prazos de entrega para os produtos que você visualizar</p>
                <div class="flex items-end gap-x-2 w-full">
                  <div class="w-full">
                    <label for="helper-text" class="mb-2 block text-sm font-medium text-gray-900">CEP</label>
                    <p-inputMask class="p-inputtext-sm" mask="99999999" [(ngModel)]="zipcode" placeholder="_____-___" [styleClass]="'w-full focus:border-pink-800'" />
                  </div>
                  <button (click)="coreLocalizationZipcodeServiceShow()" type="submit" class="rounded-md bg-white px-3 py-2.5 text-sm font-semibold text-pink-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-pink-800 hover:text-white">Buscar</button>
                </div>
                <div class="mt-6 text-center">
                  <a target="_blank" href="https://buscacepinter.correios.com.br/app/endereco/index.php" class="text-xs font-medium text-pink-900 underline">Não sei meu CEP</a>
                </div>
              </div>
            </div>

            <div class="hidden lg:flex items-center gap-x-3">
              <span (click)="onShowProfileMenu()" class="relative inline-flex">
                <svg id="profile" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="cursor-pointer w-8 h-8 text-white hover:w-9 hover:h-9 transition-all duration-200 ease-in-out">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                </svg>
                @if(showProfileMenu){
                <div class="absolute z-20 left-1/2 transform -translate-x-1/2 top-10 bg-white border border-gray-200 rounded-md p-2 shadow-lg w-56 divide-y divide-gray-200">
                  <div class="px-4 py-3">
                    @if(profile?.id){
                    <a [routerLink]="['/a']" class="text-sm font-semibold hover:text-pink-900">Olá, <span class="font-bold"> {{profile.name | titlecase}}</span></a>
                    <p class="truncate text-sm font-medium text-gray-900">{{profile.email}}</p>
                    <p class="truncate text-xs font-medium text-gray-800">ID: {{profile.client_marketplace_customer_id}}</p>
                    } @else {
                    <a [routerLink]="['/b']" class="text-sm hover:text-pink-900"><span class="font-semibold">Entre</span> ou <span class="font-semibold">cadastre-se</span></a>
                    }
                  </div>
                  @if(profile?.id){
                  <div class="py-1">
                    <a [routerLink]="['/a/order']" class="block px-4 py-2 text-sm text-gray-700 hover:font-medium hover:text-pink-900">Meus Pedidos</a>
                    <a [routerLink]="['/a']" class="block px-4 py-2 text-sm text-gray-700 hover:font-medium hover:text-pink-900">Minha Conta</a>
                  </div>
                  <div class="py-1">
                    <button (click)="collectiveCustomerUserServiceLogout();" type="button" title="Sair" class="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:font-medium hover:text-pink-900">Sair</button>
                  </div>
                  }
                </div>
                }
              </span>

              <a [routerLink]="['/a/wishlist']">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 text-white hover:w-9 hover:h-9 transition-all duration-200 ease-in-out">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z" />
                </svg>
              </a>

              @if(cart?.client_marketplace_cart_uid){
              <a [routerLink]="['/p/cart/'+cart.client_marketplace_cart_uid]" class="relative inline-flex">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 text-white hover:w-9 hover:h-9 transition-all duration-200 ease-in-out">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
                </svg>
                @if(cart?.counter_item > 0){
                <span class="sr-only">Quantidade de Itens no Carrinho</span>
                <div class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-pink-900 bg-white border-1 border-pink-900 rounded-full -top-2 -end-2">{{cart.counter_item}}</div>
                }
              </a>

              }
            </div>
          </div>
        </div>
      </div>
      @if(cart?.delivery_info?.delivery_free == 'no' && cart?.delivery_info?.diff_amount_delivery_free > 0 && cart?.counter_item > 0){
      <div class="hidden lg:flex justify-end pb-1">
        <span [routerLink]="['/p/cart/'+cart.client_marketplace_cart_uid]" class="inline-flex items-center gap-1 rounded-full bg-white px-3 py-1 text-sm font-semibold text-pink-900 cursor-pointer hover:bg-gray-100 whitespace-nowrap"> Vamos de Frete Grátis? </span>
      </div>
      }
    </nav>

    <div class="block lg:hidden bg-gray-50 z-10">
      <div class="py-2 px-4 lg:px-8 mx-auto">
        <span class="flex gap-x-1.5 text-pink-900 text-xs items-center" (click)="toggleDrawer()">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
          </svg>
          <div class="flex flex-col">
            <p class="text-gray-700 text-xs">{{headerAddress !== null || headerAddress !== undefined ? "Receber em" : ""}}</p>
            <p>{{headerAddress !== undefined ? (headerAddress.zipcode | zipcode) + ' - ' + headerAddress.city : "Informe seu CEP"}}</p>
          </div>
        </span>
      </div>
    </div>
  </header>
  <div class="hidden lg:flex justify-start px-8 bg-gray-50">
    <div class="lg:mx-auto lg:max-w-7xl w-full flex justify-between">
      <div class="flex gap-x-8 text-pink-900 text-[15px]">
        <div class="relative py-3">
          <a (mouseenter)="togglePopup('cleaning', true)" (mouseleave)="togglePopup('cleaning', false)" [routerLink]="['/s/query/Limpeza']" queryParamsHandling="merge" class="font-bold hover:font-bold cursor-pointer" [ngClass]="{'font-semibold': showCleaningCategory == false}">Itens de Limpeza</a>
          @if(showCleaningCategory){
          <div class="absolute left-0 top-10 bg-white rounded-b-md py-4 px-6 shadow-lg w-72 z-20 ease-in duration-300 flex flex-col gap-y-4" (mouseenter)="togglePopup('cleaning', true)" (mouseleave)="togglePopup('cleaning', false)">
            <a [routerLink]="['/s']" [queryParams]="{'category': 'Detergente'}" queryParamsHandling="merge" class="text-sm text-gray-800 hover:text-pink-900 hover:font-medium">Detergente</a>
            <a [routerLink]="['/s']" [queryParams]="{'category': 'Lenço Seco Eletrostático'}" class="text-sm text-gray-800 hover:text-pink-900 hover:font-medium">Lenço Seco Eletrostático!</a>
            <a [routerLink]="['/s']" [queryParams]="{'category': 'Lenço Umedecido'}" class="text-sm text-gray-800 hover:text-pink-900 hover:font-medium">Lenço Umedecido</a>
            <a [routerLink]="['/s']" [queryParams]="{'category': 'MOP'}" class="text-sm text-gray-800 hover:text-pink-900 hover:font-medium">MOP</a>
            <a [routerLink]="['/s']" [queryParams]="{'category': 'Tira Mofo'}" class="text-sm text-gray-800 hover:text-pink-900 hover:font-medium">Tira Mofo</a>
            <a [routerLink]="['/s']" [queryParams]="{'category': 'Limpador'}" class="text-sm text-gray-800 hover:text-pink-900 hover:font-medium">Limpador</a>
            <a [routerLink]="['/s']" [queryParams]="{'category': 'Sabão em Pasta'}" class="text-sm text-gray-800 hover:text-pink-900 hover:font-medium">Sabão em pasta</a>
            <a [routerLink]="['/s']" [queryParams]="{'category': 'Tira Limo'}" class="text-sm text-gray-800 hover:text-pink-900 hover:font-medium">Tira Limo</a>
            <a [routerLink]="['/s']" [queryParams]="{'category': 'Lava Louças'}" class="text-sm text-gray-800 hover:text-pink-900 hover:font-medium">Lava Louças</a>
            <a [routerLink]="['/s']" [queryParams]="{'category': 'Desinfetante'}" class="text-sm text-gray-800 hover:text-pink-900 hover:font-medium">Desinfetante</a>
            <a [routerLink]="['/s']" [queryParams]="{'category': 'Limpeza'}" class="text-sm text-gray-800 hover:text-pink-900 hover:font-medium">Limpeza</a>
            <a [routerLink]="['/s/query/Limpeza']" class="flex items-center font-medium gap-x-1 mt-3 text-sm text-gray-800 hover:text-pink-900 hover:font-medium hover:underline"
              >Ver tudo
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-3 h-3">
                <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" /></svg
            ></a>
          </div>
          }
        </div>
        <div class="relative py-3">
          <a (mouseenter)="togglePopup('kit', true)" (mouseleave)="togglePopup('kit', false)" [routerLink]="['/s/query/KIT']" queryParamsHandling="merge" class="font-bold hover:font-bold cursor-pointer" [ngClass]="{'font-semibold': showKitCategory == false}">Kits</a>
          @if(showKitCategory){
          <div class="absolute left-0 top-10 bg-white rounded-b-md py-4 px-6 shadow-lg w-72 z-20 ease-in duration-300 flex flex-col gap-y-4" (mouseenter)="togglePopup('kit', true)" (mouseleave)="togglePopup('kit', false)">
            <a [routerLink]="['/s']" [queryParams]="{'category': 'KIT - Cuidados com as Roupas'}" class="text-sm text-gray-800 hover:text-pink-900 hover:font-medium">KIT - Cuidados com as Roupas</a>
            <a [routerLink]="['/s']" [queryParams]="{'category': 'KIT - Cuidados com a Casa'}" class="text-sm text-gray-800 hover:text-pink-900 hover:font-medium">KIT - Cuidados com a Casa</a>
            <a [routerLink]="['/s']" [queryParams]="{'category': 'Kit Misto'}" class="text-sm text-gray-800 hover:text-pink-900 hover:font-medium">Kit Misto</a>
            <a [routerLink]="['/s/query/KIT']" class="flex items-center font-medium gap-x-1 mt-3 text-sm text-gray-800 hover:text-pink-900 hover:font-medium hover:underline"
              >Ver tudo
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-3 h-3">
                <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
              </svg>
            </a>
          </div>
          }
        </div>
        <div class="relative py-3">
          <a (mouseenter)="togglePopup('laundry', true)" (mouseleave)="togglePopup('laundry', false)" [routerLink]="['/s/query/Lavanderia']" queryParamsHandling="merge" class="font-bold hover:font-bold cursor-pointer py-2" [ngClass]="{'font-semibold': showLaundryCategory == false}">Lavanderia</a>
          @if(showLaundryCategory){
          <div class="absolute left-0 top-10 bg-white rounded-b-md py-4 px-6 shadow-lg w-72 z-20 ease-in duration-300 flex flex-col gap-y-4" (mouseenter)="togglePopup('laundry', true)" (mouseleave)="togglePopup('laundry', false)">
            <a [routerLink]="['/s']" [queryParams]="{'category': 'Tira Manchas'}" class="text-sm text-gray-800 hover:text-pink-900 hover:font-medium">Tira Manchas</a>
            <a [routerLink]="['/s']" [queryParams]="{'category': 'Lavanderia'}" class="text-sm text-gray-800 hover:text-pink-900 hover:font-medium">Lavanderia</a>
            <a [routerLink]="['/s']" [queryParams]="{'category': 'Lava Roupas'}" class="text-sm text-gray-800 hover:text-pink-900 hover:font-medium">Lava Roupas</a>
            <a [routerLink]="['/s']" [queryParams]="{'category': 'Amaciantes'}" class="text-sm text-gray-800 hover:text-pink-900 hover:font-medium">Amaciantes</a>
            <a [routerLink]="['/s']" [queryParams]="{'category': 'Facilitadores'}" class="text-sm text-gray-800 hover:text-pink-900 hover:font-medium">Facilitadores</a>

            <a [routerLink]="['/s/query/Lavanderia']" class="flex items-center font-medium gap-x-1 mt-3 text-sm text-gray-800 hover:text-pink-900 hover:font-medium hover:underline">
              Ver tudo
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-3 h-3">
                <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
              </svg>
            </a>
          </div>
          }
        </div>
      </div>

      <div class="flex gap-x-8 text-pink-900 font-semibold text-[15px]">
        <a [routerLink]="['/s/sale/yes']" class="hover:font-bold cursor-pointer py-3">Promoções</a>
        <a [routerLink]="['/subscription']" class="hover:font-bold cursor-pointer py-3 whitespace-nowrap">Clube Gê</a>
        <a [routerLink]="['/salesman']" class="hover:font-bold cursor-pointer py-3">Marcas</a>
        <a [routerLink]="['/blog']" class="hover:font-bold cursor-pointer py-3">Blog</a>
      </div>
    </div>
  </div>
</div>
}

<!-- Backdrop -->
<div [ngClass]="{'hidden': !drawerOpen, 'fixed inset-0 bg-black opacity-50 z-50': drawerOpen}" (click)="toggleDrawer()"></div>

<!-- drawer component -->
<div [ngClass]="{'translate-y-full': !drawerOpen, 'translate-y-0': drawerOpen}" class="font-heading mx-auto fixed bottom-0 left-0 right-0 z-50 w-full transform-none overflow-y-auto bg-white px-4 py-6 transition-transform">
  <h5 id="drawer-bottom-label" class="mb-4 inline-flex items-center text-base font-semibold text-pink-900">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="mr-3 h-5 w-5">
      <path
        fill-rule="evenodd"
        d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
        clip-rule="evenodd"
      />
    </svg>
    Escolha onde deseja receber suas compras
  </h5>
  <button type="button" (click)="toggleDrawer()" aria-controls="drawer-bottom-example" class="absolute end-2.5 top-2.5 inline-flex h-8 w-8 items-center justify-center rounded-lg bg-transparent text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900">
    <svg class="h-3 w-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
    </svg>
    <span class="sr-only">Fechar</span>
  </button>
  <p class="mb-6 max-w-lg text-sm text-gray-500">Tenha informações detalhadas sobre valores e prazos de entrega para os produtos que você visualizar</p>
  <div class="flex items-end gap-x-2 w-full">
    <div class="w-full">
      <label for="helper-text" class="mb-2 block text-sm font-medium text-gray-900">CEP</label>
      <p-inputMask class="p-inputtext-sm" mask="99999999" [(ngModel)]="zipcode" placeholder="_____-___" [styleClass]="'w-full focus:border-pink-800'" />
    </div>
    <button (click)="coreLocalizationZipcodeServiceShow()" type="submit" class="rounded-md bg-white px-3 py-2.5 text-sm font-semibold text-pink-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-pink-800 hover:text-white">Buscar</button>
  </div>
  <div class="mt-6 text-center">
    <a target="_blank" href="https://buscacepinter.correios.com.br/app/endereco/index.php" class="text-xs font-medium text-pink-900 underline">Não sei meu CEP</a>
  </div>
</div>
