import { environment } from 'src/environments/environment';
import { ReplaySubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// [[Providers]]
import { ErrorService } from '~services/error.service';
import { CacheService } from '~services/cache.service';
// ]
@Injectable()
export class CollectiveMarketplaceCoreVisualBannerService {
  constructor(private router: Router, public http: HttpClient, public error: ErrorService, public cacheService: CacheService) {}

  bannerSubject = new ReplaySubject();
  setBanners(objectData: any) {
    this.bannerSubject.next(JSON.stringify(objectData));
  }

  getBanners() {
    return this.bannerSubject;
  }

  /**
   *
   * @param data
   */
  getQuerystring(data: any) {
    let querystring: any = '';
    for (let entry in data) {
      querystring += entry + '=' + encodeURIComponent(data[entry]) + '&';
    }
    return querystring;
  }

  /**
   *
   * @param data
   */
  getPost(data: any) {
    let postData: any = {};
    for (let entry in data) {
      if (typeof data[entry] == 'object') {
        if (data[entry] == null) {
          postData[entry] = null;
        } else {
          postData[entry] = JSON.stringify(data[entry]);
        }
      } else {
        postData[entry] = data[entry];
      }
    }
    return postData;
  }

  /**
   *
   * @param data
   */
  index(data: any) {
    let querystring = this.getQuerystring(data);

    let request = this.http.get(environment.api_url + 'collective/client/marketplace/' + environment.marketplace.hostname + '/core/visual/banner?' + querystring, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return request;
  }

  /**
   *
   * @param data
   */
  view(data: any) {
    let querystring = this.getQuerystring(data);

    let request = this.http.get(environment.api_url + 'collective/client/marketplace/' + environment.marketplace.hostname + '/core/visual/banner/' + data.id + '?' + querystring, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return request;
  }
}
