import { environment } from 'src/environments/environment';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// -----------------------------------------------------------------------------
import { LayoutGuardComponent, LayoutWwwComponent } from 'src/project/layout';
// -----------------------------------------------------------------------------

const routes: Routes = [
  {
    path: '',
    component: LayoutWwwComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('../project/modules/www/module').then((m) => m.WwwModule),
      },
    ],
  },
  {
    path: 'a',
    component: LayoutWwwComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('../project/modules/certs/module').then((m) => m.CertsModule),
      },
    ],
  },
  {
    path: 'b',
    component: LayoutWwwComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('../project/modules/blocs/module').then((m) => m.BlocsModule),
      },
    ],
  },
  {
    path: 'p',
    component: LayoutWwwComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('../project/modules/page/module').then((m) => m.PageModule),
      },
    ],
  },
  {
    path: 'article',
    component: LayoutWwwComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('../project/modules/article/module').then((m) => m.ArticleModule),
      },
    ],
  },
  {
    path: 'blog',
    component: LayoutWwwComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('../project/modules/blog/module').then((m) => m.ArticleModule),
      },
    ],
  },

  {
    path: '**',
    component: LayoutWwwComponent,
    loadChildren: () => import('../project/modules/404/module').then((m) => m.Page404Module),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
