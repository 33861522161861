<div class="lg:hidden font-heading fixed bottom-0 left-0 z-40 h-20 w-full border-t border-gray-200 bg-white shadow">
  <div class="mx-auto grid h-full max-w-lg grid-cols-4 font-medium">
    <button [routerLink]="['/']" (click)="drawerOpen = false" type="button" class="group relative inline-flex items-center justify-center px-5">
      @if(actualUrl == '/' && !drawerOpen ){
      <span class="absolute top-0 h-1 min-w-28 bg-pink-900 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full" aria-hidden="true"></span>
      <div class="flex flex-col items-center text-pink-900">
        <svg class="mb-2 h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
          <path d="M11.47 3.841a.75.75 0 0 1 1.06 0l8.69 8.69a.75.75 0 1 0 1.06-1.061l-8.689-8.69a2.25 2.25 0 0 0-3.182 0l-8.69 8.69a.75.75 0 1 0 1.061 1.06l8.69-8.689Z" />
          <path d="m12 5.432 8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 0 1-.75-.75v-4.5a.75.75 0 0 0-.75-.75h-3a.75.75 0 0 0-.75.75V21a.75.75 0 0 1-.75.75H5.625a1.875 1.875 0 0 1-1.875-1.875v-6.198a2.29 2.29 0 0 0 .091-.086L12 5.432Z" />
        </svg>
        <span class="text-xs">Home</span>
      </div>
      } @else {
      <div class="flex flex-col items-center text-gray-500">
        <svg class="mb-2 h-6 w-6" xmlns="http://www.w3.org/2000/svg" [attr.fill]="iconColor === 'pink-800' ? '#D61C4E' : 'none'" viewBox="0 0 24 24" [attr.stroke]="iconColor === 'pink-800' ? '#D61C4E' : 'currentColor'" stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
        </svg>
        <span class="text-xs">Home</span>
      </div>
      }
    </button>
    <button (click)="toggleDrawer()" type="button" class="group inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50">
      @if(actualUrl.match('/s') && !actualUrl.includes('cart') && !actualUrl.includes('success') && !actualUrl.includes('subscription') && !actualUrl.includes('store') || drawerOpen){
      <span class="absolute top-0 h-1 min-w-28 bg-pink-900 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full" aria-hidden="true"></span>
      <div class="flex flex-col items-center text-pink-900">
        <svg class="mb-2 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
        </svg>
        <span class="text-xs">Buscar</span>
      </div>
      } @else{
      <button class="flex flex-col items-center text-gray-500">
        <svg class="mb-2 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
        </svg>
        <span class="text-xs">Buscar</span>
      </button>
      }
    </button>
    <button [routerLink]="['/a']" (click)="drawerOpen = false" type="button" class="group inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50">
      @if(actualUrl.match('/a') && !actualUrl.match('/p/') && !actualUrl.match('/s/') && !drawerOpen){
      <span class="absolute top-0 h-1 min-w-28 bg-pink-900 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full" aria-hidden="true"></span>
      <div class="flex flex-col items-center text-pink-900">
        <svg class="mb-2 h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
          <path fill-rule="evenodd" d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z" clip-rule="evenodd" />
        </svg>

        <span class="text-xs">Perfil</span>
      </div>
      } @else{
      <div class="flex flex-col items-center text-gray-500">
        <svg class="mb-2 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
        </svg>

        <span class="text-xs">Perfil</span>
      </div>
      }
    </button>
    @if(cart?.client_marketplace_cart_uid){
    <button [routerLink]="['/p/cart/'+cart.client_marketplace_cart_uid]" (click)="drawerOpen = false" type="button" class="group inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50">
      @if((actualUrl.match('/p/cart') || actualUrl.match('/p/checkout')) && !drawerOpen){
      <span class="absolute top-0 h-1 min-w-28 bg-pink-900 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full" aria-hidden="true"></span>
      <div class="flex flex-col items-center text-pink-900">
        <svg class="mb-2 h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
          <path
            d="M2.25 2.25a.75.75 0 0 0 0 1.5h1.386c.17 0 .318.114.362.278l2.558 9.592a3.752 3.752 0 0 0-2.806 3.63c0 .414.336.75.75.75h15.75a.75.75 0 0 0 0-1.5H5.378A2.25 2.25 0 0 1 7.5 15h11.218a.75.75 0 0 0 .674-.421 60.358 60.358 0 0 0 2.96-7.228.75.75 0 0 0-.525-.965A60.864 60.864 0 0 0 5.68 4.509l-.232-.867A1.875 1.875 0 0 0 3.636 2.25H2.25ZM3.75 20.25a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM16.5 20.25a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z"
          />
        </svg>
        <span class="text-xs">Carrinho</span>
      </div>
      } @else { @if(cart?.client_marketplace_cart_uid){
      <div class="flex flex-col items-center text-gray-500 relative">
        <svg class="mb-2 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
        </svg>
        @if(cart?.counter_item > 0){
        <span class="sr-only">Quantidade de Itens no Carrinho</span>
        <div class="absolute inline-flex items-center justify-center w-5 h-5 text-xs font-semibold text-white bg-pink-900 border-1 rounded-full -top-2 end-0.5">{{cart.counter_item}}</div>
        }
        <span class="text-xs">Carrinho</span>
      </div>
      } }
    </button>
    }
  </div>
</div>

<footer class="w-full">
  <div>
    @if(!actualUrl.includes('/social')){ @if(!actualUrl.match('/p/(.*)/id') && !actualUrl.match('/p/cart') && !actualUrl.match('/p/checkout') && !actualUrl.match('/a/(.*)') && !actualUrl.match('/b/(.*)') && !actualUrl.match('/voucher/(.*)')){
    <div class="mx-auto max-w-7xl px-4 pt-6 pb-4 sm:py-10 md:px-6">
      <ngx-slick-carousel class="hidden lg:block carousel overflow-x-hidden overflow-y-hidden justify-center w-full" #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem class="slide" *ngFor="let banner of fullBannerFooterBanners; trackBy: trackByUrl">
          <a href="{{banner.url}}">
            <img src="{{ banner.url_image }}" alt="" width="100%" class="object-contain rounded-md" />
          </a>
        </div>
      </ngx-slick-carousel>
      <ngx-slick-carousel class="block lg:hidden carousel overflow-x-hidden overflow-y-hidden justify-center w-full" #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem class="slide" *ngFor="let banner of fullBannerFooterBanners; trackBy: trackByUrl">
          <img src="{{ banner.url_image_mobile }}" alt="" width="100%" class="object-contain rounded-md" />
        </div>
      </ngx-slick-carousel>
    </div>
    }

    <div id="form">
      <div>
        @if(showCloseButton){
        <div class="flex items-end justify-end">
          <button id="closeButton" (click)="closeForm()" class="p-2 bg-gray-100 rounded-t-md">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-gray-500 hover:text-gray-400">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        }
        <embedForm [form_id]="'1'"></embedForm>
      </div>
    </div>

    }
    <!-- Compra Segura e Cartões -->
    <div class="flex flex-col justify-center items-center bg-pink-800 mx-auto sm:flex-row py-8 space-y-8 sm:space-y-0">
      <div class="flex items-center justify-center space-x-2 sm:flex-col sm:space-y-2 px-8">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="stroke-white w-8 h-8 sm:w-10 sm:h-10">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" />
        </svg>
        <p class="text-white font-semibold text-xs sm:text-lg">Compra 100% segura</p>
      </div>
      <div class="flex sm:justify-center flex-col sm:items-center sm:mt-2 space-y-3 px-8">
        <p class="text-white text-xs sm:text-sm text-center font-semibold">Formas de pagamento aceitas</p>
        <div><img class="w-96" src="/assets/images/Bandeiras-horizontal-grande-svg.png" alt="Formas de Pagamento Aceitas" /></div>
      </div>
    </div>

    <div [ngClass]="{'justify-start': actualUrl.includes('/social')}" class="flex flex-col sm:items-start bg-[#f5f5f5] sm:flex-row mx-auto justify-center">
      <!-- Todos os Departamentos -->
      @if(!actualUrl.includes('/social')){
      <div class="p-8">
        <p class="text-pink-800 text-sm font-semibold sm:text-base">
          <a [routerLink]="['/s']">Todos os departamentos</a>
        </p>
        <div class="sm:flex sm:flex-col sm:items-center">
          <div class="grid grid-cols-3 gap-x-6 sm:gap-x-8 overflow-auto mt-4 no-scrollbar sm:grid-cols-3 sm:grid-rows-none sm:gap-y-2 sm:overflow-hidden">
            <ng-container *ngFor="let category of categories?.data;">
              <a [routerLink]="['/s/category/'+category.name_handle]" class="text-gray-700 text-xs p-2 sm:text-sm hover:font-medium whitespace-pre-line">{{category.name}}</a>
            </ng-container>
          </div>
          <div class="mt-3 sm:flex sm:justify-center"><a [routerLink]="['/s']" class="text-xs p-2 text-gray-800 sm:text-sm font-semibold text-center">Ver todos</a></div>
        </div>
      </div>
      }

      <!-- Duvidas -->
      <div [ngClass]="{'grid grid-cols-3 gap-x-8 space-y-0 justify-start': actualUrl.includes('/social')}" class="p-8 flex flex-col space-y-6 justify-center md:mt-4">
        <div>
          <p class="text-pink-800 text-sm font-semibold sm:text-base">Dúvidas?</p>
          <div class="sm:flex sm:flex-col sm:space-x-0 mt-4 space-y-4">
            <a href="https://wa.me/+5511966294417" target="_blank" class="text-gray-700 text-xs underline block">(11) 96629-4417</a>
            <p class="text-gray-700 text-xs block">{{marketplace?.email}}</p>
          </div>
        </div>

        <div>
          <p class="text-pink-800 text-sm font-semibold sm:text-base">Políticas</p>
          <div class="mt-4 space-y-2">
            <a [routerLink]="['/article/politica-de-privacidade']" class="text-gray-700 text-xs underline block">Privacidade</a>
            <a [routerLink]="['/article/politica-de-troca']" class="text-gray-700 text-xs underline block">Trocas</a>
            <a [routerLink]="['/article/politica-de-pagamento']" class="text-gray-700 text-xs underline block">Pagamento</a>
            <a [routerLink]="['/article/politica-de-desconto']" class="text-gray-700 text-xs underline block">Desconto</a>
            <a [routerLink]="['/article/termos-e-condicoes']" class="text-gray-700 text-xs underline block">Termos e Condições</a>
          </div>
        </div>

        <div>
          <p class="text-pink-800 text-sm font-semibold sm:text-base">Empresa</p>
          <div class="mt-4 space-y-2">
            <a [routerLink]="['/page/nos']" class="text-gray-700 text-xs underline block">Nós</a>
          </div>
        </div>
      </div>
    </div>

    <!-- Informações -->
    <div [ngClass]="{'px-0 pb-8': actualUrl.includes('/social')}" class="mt-8 px-4 md:flex md:items-center md:justify-between mx-auto max-w-5xl sm:mt-12 z-10">
      <div class="flex space-x-6 md:order-2">
        <a href="https://www.facebook.com/casadege.com.br" target="_blank"><img class="w-6 opacity-40 hover:opacity-20" src="/assets/images/redes_sociais/facebook.webp" alt="Facebook" /></a>
        <a href="https://instagram.com/casadege" target="_blank"><img class="w-6 opacity-40 hover:opacity-20" src="/assets/images/redes_sociais/instagram.webp" alt="Instagram" /></a>
        <a href="https://youtube.com.br/channel/UC9sKpdku8Hy7t2Cru0vuaMw" target="_blank"><img class="w-6 opacity-40 hover:opacity-20" src="/assets/images/redes_sociais/youtube.webp" alt="Youtube" /></a>
      </div>
      <p class="mt-8 text-sm text-gray-500 md:order-1 md:mt-0">
        &copy; 26.756.989/0001-51 DBS COMERCIAL DE PRODUTOS DE LIMPEZA LTDA<br />
        Avenida Papa João Paulo I, 5663 - Jd Presidente Dutra - Guarulhos / SP - CEP: 07174-000 | (11) 96629-4417
      </p>
    </div>
  </div>
</footer>

<!-- drawer component -->
@if(drawerOpen){
<div [ngClass]="{'translate-y-full': !drawerOpen, 'translate-y-0': drawerOpen}" class="font-heading mx-auto h-screen fixed bottom-0 z-30 w-full transform-none bg-gray-50 transition-transform overflow-y-hidden">
  <form method="POST" (ngSubmit)="clientMarketplaceCoreProductServiceRedirectSearch();" class="relative flex items-center w-full pt-6 px-6">
    <label for="search" class="sr-only">Buscar</label>
    <div class="relative w-full">
      <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
        <svg class="w-4 h-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
        </svg>
      </div>
      <input
        [(ngModel)]="searchParams.query"
        #searchParams.query="ngModel"
        (keyup)="clientMarketplaceCoreProductServiceIndex()"
        type="text"
        class="bg-gray-50 lg:bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-pink-500 focus:border-pink-500 block w-full ps-10 p-2.5"
        placeholder="O que você procura hoje?"
        required
        (focus)="onFocus()"
        (blur)="onBlur()"
      />
      <button title="Limpar busca" (click)="clientMarketplaceCoreProductServiceClear()" type="button" class="absolute inset-y-0 end-0 flex items-center pe-3">
        <svg class="w-4 h-4 text-gray-500 hover:text-gray-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
        </svg>
      </button>
    </div>
    @if((searchResults?.data && searchParams.query !== '') && showSuggestions){
    <div class="absolute max-w-fit h-[80vh] z-20 left-0 top-20 space-y-5 p-4 mx-6 bg-white shadow rounded-md flex flex-col overflow-y-auto no-scrollbar">
      @if(searchResults?.data && searchParams.query !== '') {
      <p class="text-lg font-semibold text-pink-900">Produtos sugeridos ({{searchResults?.total}})</p>
      <ul role="list" class="grid grid-cols-2 gap-2">
        <ng-container *ngFor="let product of searchResults?.data; let index = index">
          <ng-container *ngIf="index < 6">
            <IncludeProductContainer [cart]="cart" [product]="product"></IncludeProductContainer>
          </ng-container>
        </ng-container>
      </ul>
      <button (click)="clientMarketplaceCoreProductServiceRedirectSearch();" class="flex justify-center gap-x-1 items-center text-sm text-gray-900 bg-gray-50 rounded-md p-2">Ver todos os resultados para <span class="font-semibold"> {{searchParams.query}}</span></button>
      }
    </div>

    }
  </form>
  @if(searchParams.query == ''){
  <div class="mt-6 mb-6 grid grid-cols-3 h-full">
    <div class="min-h-[100%] col-span-1 flex flex-col gap-y-4 text-sm w-full font-semibold text-pink-900 bg-gray-50">
      <button (click)="onChangeCategoryView('Cleaning')" class="w-full py-2 text-left pl-6" [ngClass]="{'font-bold bg-white': selectedCategory == 'Cleaning'}">Itens de Limpeza</button>
      <button (click)="onChangeCategoryView('Kits')" class="w-full py-2 text-left pl-6" [ngClass]="{'font-bold bg-white': selectedCategory == 'Kits'}">Kits</button>
      <button (click)="onChangeCategoryView('Laundry')" class="w-full py-2 text-left pl-6" [ngClass]="{'font-bold bg-white': selectedCategory == 'Laundry'}">Lavanderia</button>
      <a [routerLink]="['/s/sale/yes']" class="hover:font-bold cursor-pointer py-2 pl-6">Promoções</a>
      <a [routerLink]="['/subscription']" class="hover:font-bold cursor-pointer py-2 pl-6">Clube Gê</a>
      <a [routerLink]="['/salesman']" class="hover:font-bold cursor-pointer py-2 pl-6">Marcas</a>
      <a [routerLink]="['/blog']" class="hover:font-bold cursor-pointer py-2 pl-6">Blog</a>
    </div>
    <div class="col-span-2 bg-white">
      @if(selectedCategory == 'Cleaning'){
      <div class="grid grid-cols-2 gap-6 px-6 mt-4">
        <a [routerLink]="['/s']" [queryParams]="{'category': 'Detergente'}" queryParamsHandling="merge" class="text-sm text-gray-800 hover:text-pink-900 hover:font-medium">Detergente</a>
        <a [routerLink]="['/s']" [queryParams]="{'category': 'Lenço Seco Eletrostático'}" class="text-sm text-gray-800 hover:text-pink-900 hover:font-medium">Lenço Seco Eletrostático</a>
        <a [routerLink]="['/s']" [queryParams]="{'category': 'Lenço Umedecido'}" class="text-sm text-gray-800 hover:text-pink-900 hover:font-medium">Lenço Umedecido</a>
        <a [routerLink]="['/s']" [queryParams]="{'category': 'MOP'}" class="text-sm text-gray-800 hover:text-pink-900 hover:font-medium">MOP</a>
        <a [routerLink]="['/s']" [queryParams]="{'category': 'Tira Mofo'}" class="text-sm text-gray-800 hover:text-pink-900 hover:font-medium">Tira Mofo</a>
        <a [routerLink]="['/s']" [queryParams]="{'category': 'Limpador'}" class="text-sm text-gray-800 hover:text-pink-900 hover:font-medium">Limpador</a>
        <a [routerLink]="['/s']" [queryParams]="{'category': 'Sabão em Pasta'}" class="text-sm text-gray-800 hover:text-pink-900 hover:font-medium">Sabão em pasta</a>
        <a [routerLink]="['/s']" [queryParams]="{'category': 'Tira Limo'}" class="text-sm text-gray-800 hover:text-pink-900 hover:font-medium">Tira Limo</a>
        <a [routerLink]="['/s']" [queryParams]="{'category': 'Lava Louças'}" class="text-sm text-gray-800 hover:text-pink-900 hover:font-medium">Lava Louças</a>
        <a [routerLink]="['/s']" [queryParams]="{'category': 'Desinfetante'}" class="text-sm text-gray-800 hover:text-pink-900 hover:font-medium">Desinfetante</a>
        <a [routerLink]="['/s']" [queryParams]="{'category': 'Limpeza'}" class="text-sm text-gray-800 hover:text-pink-900 hover:font-medium">Limpeza</a>
        <a [routerLink]="['/s/query/Limpeza']" class="flex items-center font-medium text-sm text-gray-800 hover:text-pink-900 hover:font-medium hover:underline">
          Ver tudo
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-3 h-3">
            <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
          </svg>
        </a>
      </div>
      } @if(selectedCategory == 'Kits'){
      <div class="grid grid-cols-2 gap-6 px-6 mt-4">
        <a [routerLink]="['/s']" [queryParams]="{'category': 'KIT - Cuidados com as Roupas'}" class="text-sm text-gray-800 hover:text-pink-900 hover:font-medium">KIT - Cuidados com as Roupas</a>
        <a [routerLink]="['/s']" [queryParams]="{'category': 'KIT - Cuidados com a Casa'}" class="text-sm text-gray-800 hover:text-pink-900 hover:font-medium">KIT - Cuidados com a Casa</a>
        <a [routerLink]="['/s']" [queryParams]="{'category': 'Kit Misto'}" class="text-sm text-gray-800 hover:text-pink-900 hover:font-medium">Kit Misto</a>
        <a [routerLink]="['/s/query/KIT']" class="flex items-center font-medium text-sm text-gray-800 hover:text-pink-900 hover:font-medium hover:underline">
          Ver tudo
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-3 h-3">
            <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
          </svg>
        </a>
      </div>
      } @if(selectedCategory == 'Laundry'){
      <div class="grid grid-cols-2 gap-6 px-6 mt-4">
        <a [routerLink]="['/s']" [queryParams]="{'category': 'Tira Manchas'}" class="text-sm text-gray-800 hover:text-pink-900 hover:font-medium">Tira Manchas</a>
        <a [routerLink]="['/s']" [queryParams]="{'category': 'Lavanderia'}" class="text-sm text-gray-800 hover:text-pink-900 hover:font-medium">Lavanderia</a>
        <a [routerLink]="['/s']" [queryParams]="{'category': 'Lava Roupas'}" class="text-sm text-gray-800 hover:text-pink-900 hover:font-medium">Lava Roupas</a>
        <a [routerLink]="['/s']" [queryParams]="{'category': 'Amaciantes'}" class="text-sm text-gray-800 hover:text-pink-900 hover:font-medium">Amaciantes</a>
        <a [routerLink]="['/s']" [queryParams]="{'category': 'Facilitadores'}" class="text-sm text-gray-800 hover:text-pink-900 hover:font-medium">Facilitadores</a>
        <a [routerLink]="['/s/query/Lavanderia']" class="flex items-center font-medium text-sm text-gray-800 hover:text-pink-900 hover:font-medium hover:underline">
          Ver tudo
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-3 h-3">
            <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
          </svg>
        </a>
      </div>
      }
    </div>
  </div>
  }
</div>
}
