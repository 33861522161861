declare let gtag: Function;
declare let fbq: Function;
declare global {
  interface Window {
    VLibras: any;
  }
}
import { environment } from 'src/environments/environment';
import { Component, ViewChild, ElementRef, HostListener, ComponentFactoryResolver, Renderer2, RendererFactory2 } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
// [[PrimeNg]]
import { MessageService } from 'primeng/api';
// [[Providers]]
import { CustomerUserService } from 'src/project/services/panel/client/marketplace/customer/user/user.service';
import { CollectiveMarketplaceService } from '~services/collective/client/marketplace/marketplace.service';
import { ClientMarketplaceCoreCategoryService } from '~services/collective/client/marketplace/core/category/category.service';
import { CollectiveMarketplaceCartService } from '~services/collective/client/marketplace/cart/cart.service';
import { CollectiveMarketplaceCoreVisualBannerService } from '~services/collective/client/marketplace/core/visual/banner/banner.service';
import { ClientMarketplaceCoreProductService } from '~services/collective/client/marketplace/core/product/product.service';
import { CoreLocalizationZipcodeService } from 'src/project/services/collective/core/localization/zipcode.service';

// ]
// [[Interfaces do Componente]]
// ]
@Component({
  selector: 'app-panel-header',
  templateUrl: './component.html',
  styleUrls: ['./component.scss'],
})
export class WwwHeaderComponent {
  /**
   * Base de uso em Component
   */
  public environment = environment;
  messageProcess = false;
  messageIsError: any;
  profile: any = undefined;
  marketplace: any = null;
  cart: any = null;
  banners: any = null;
  banners_fnc_cta: any = null;
  subscribedMethods: any = [];
  fullBannerTopFootBanners: [];
  fullBannerHomeBanners: any;
  middleBannerHomeBanners: [];
  bottomBannerHomeBanners: [];
  fullBannerTopHead: [];
  searchHistory = [];
  autoplay: boolean = false;
  infinite: boolean = false;
  favicon: HTMLLinkElement = document.querySelector('#app_icon');

  actualUrl: any;
  openMenuMobile: any = false;
  openSubMenuMobile: any = false;
  headerAddress: any;
  address: any = {
    street: '',
    neighborhood: '',
    city: '',
    state: '',
    zipcode: '',
  };

  private renderer: Renderer2;

  constructor(
    /**
     * Serviços da Aplicação
     */
    private router: Router,
    private rendererFactory: RendererFactory2,
    private activatedRoute: ActivatedRoute,
    private resolver: ComponentFactoryResolver,
    private elementRef: ElementRef,
    /**
     * PrimeNg
     */
    private messageService: MessageService,
    /**
     * Base de uso em Component
     */
    private userService: CustomerUserService,
    private collectiveMarketplaceService: CollectiveMarketplaceService,
    private collectiveMarketplaceCartService: CollectiveMarketplaceCartService,
    private collectiveMarketplaceCoreVisualBannerService: CollectiveMarketplaceCoreVisualBannerService,
    private clientMarketplaceCoreProductService: ClientMarketplaceCoreProductService,
    /**
     * Services
     */
    private clientMarketplaceCoreCategoryService: ClientMarketplaceCoreCategoryService,
    private coreLocalizationZipcodeService: CoreLocalizationZipcodeService
  ) {
    /**
     * Profile
     *
     * Request inicial do PROFILE para acompanhamento nas Páginas.
     */
    this.userService.profile().subscribe((Profile: any) => {
      if (Profile != null) {
        this.userService.setUser(Profile.data);
      }
    });
    // ;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;

    /**
     * Marketplace
     *
     * Request inicial do MARKETPLACE para acompanhamento nas Páginas.
     */
    this.collectiveMarketplaceService.marketplace().subscribe((Marketplace: any) => {
      if (Marketplace != null) {
        this.collectiveMarketplaceService.setMarketplace(Marketplace.data);
      }
    });
    // ;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;

    /**
     * Cart
     *
     * Request inicial do CARRINHO para acompanhamento nas Páginas.
     */
    this.collectiveMarketplaceCartService.cart({}).subscribe((Cart: any) => {
      if (Cart != null) {
        this.collectiveMarketplaceCartService.setCart(Cart.data);
      }
    });
    // ;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;

    /**
     * Banners
     *
     * Request inicial do BANNER para acompanhamento nas Páginas.
     */
    this.collectiveMarketplaceCoreVisualBannerService.index({ per_page: 100, core_visual_banner_status: 'active' }).subscribe((Banner: any) => {
      if (Banner != null) {
        this.collectiveMarketplaceCoreVisualBannerService.setBanners(Banner.data.data);
      }
    });
    // ;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;

    {
      if (localStorage.getItem('boxPriv') != null) {
        this.boxPriv = false;
      }

      this.renderer = rendererFactory.createRenderer(null, null);

      this.clientMarketplaceCoreCategoryServiceIndexMajor();
    }

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.actualUrl = this.router.url;
  }

  /**
   * on init
   */
  ngOnInit() {
    this.ipBuildEcommerceDependencies();
    this.searchHistory = JSON.parse(localStorage.getItem('search_history'));
  }

  /**
   * build ecommerce dependencies
   */
  ipBuildEcommerceDependencies() {
    /**
     * Base
     *
     * Request da base de uso.
     */
    // PROFILE
    this.userService.getUser().subscribe((Profile: any) => {
      this.messageProcess = true;
      if (Profile != null) {
        if (JSON.parse(Profile) != null) {
          this.profile = JSON.parse(Profile);
          this.messageProcess = false;
        }
      }
    });
    // MARKETPLACE
    this.collectiveMarketplaceService.getMarketplace().subscribe((Marketplace: any) => {
      if (Marketplace != null) {
        if (JSON.parse(Marketplace) != null) {
          this.marketplace = JSON.parse(Marketplace);
          /**
           *
           * CONFIGURAÇÕES ADICIONAIS DESTE `COMPONENTE`
           *
           */

          this.favicon.href = '/assets/themes/' + environment.marketplace.domainObject.sld + '/images/favicon.png';
        }
      }
    });
    // CART
    this.collectiveMarketplaceCartService.getCart().subscribe((Cart: any) => {
      if (Cart != null) {
        if (JSON.parse(Cart) != null) {
          this.cart = JSON.parse(Cart);
        }
      }
    });
    // BANNERS
    this.banners_fnc_cta = function (banner: any) {
      if (banner.url != '') {
        document.location = banner.url;
      }
      return false;
    };
    this.collectiveMarketplaceCoreVisualBannerService.getBanners().subscribe((Data: any) => {
      if (Data != null) {
        if (JSON.parse(Data) != null) {
          this.banners = JSON.parse(Data);

          let bannersCopy = this.banners.slice();
          this.fullBannerTopHead = bannersCopy.filter((banner) => banner.core_visual_banner_area === 'full_banner_top_head');

          if (this.fullBannerTopHead.length > 1) {
            this.autoplay = true;
            this.infinite = true;
          }
        }
      }
    });
    //LOCALIZATION
    this.coreLocalizationZipcodeService.getZipcode().subscribe((zipcode: any) => {
      if (zipcode != null) {
        this.headerAddress = zipcode;
      }
    });

    // # /*
    // # |--------------------------------------------------------------------------
    // # | Subscribers: Search
    // # |--------------------------------------------------------------------------
    // # |
    // # */
    var _ = this.clientMarketplaceCoreProductService.getSearch().subscribe((searchReplaySubject: any) => {
      this.search.query = searchReplaySubject.query;
      this.clientMarketplaceCoreProductService.search(searchReplaySubject).subscribe((searchResults: any) => {
        this.clientMarketplaceCoreProductService.setSearchResult(searchResults);
      });
    });
    this.subscribedMethods.push(_);

    var _ = this.clientMarketplaceCoreProductService.getSearchResult().subscribe((searchResults: any) => {
      this.searchResults = searchResults;
    });
    this.subscribedMethods.push(_);
  }

  ngOnDestroy() {
    this.subscribedMethods.forEach((subscriber: any, key: number) => {
      // console.log(this.subscribedMethods[key]);
      subscriber.unsubscribe();
    });
  }

  /**
   * after view init
   */
  ngAfterViewInit() {
    this.headerMenuFixedOnTop();
  }

  slideConfig = {
    slidesToShow: 1,
    dots: false,
    infinite: this.infinite,
    autoplay: this.autoplay,
    arrows: false,

    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  trackByUrl(index: number, banner: any): string {
    return banner.url_image_mobile;
  }

  boxPriv: any = true;
  /**
   * close privacy
   */
  fnClosePrivacy() {
    localStorage.setItem('boxPriv', 'no');
    this.boxPriv = false;
  }

  categories: any;
  clientMarketplaceCoreCategoryServiceIndexMajor() {
    var _ = this.clientMarketplaceCoreCategoryService.index_major({ per_page: 100 });
    if (_ !== null) {
      _.subscribe(
        (response: any) => {
          this.categories = response.data.data;
        },
        (error) => {}
      );
    }
  }

  categoryChildren: any;
  currentCategory: any;
  showCategoryChildren: boolean = false;

  getChildren(children: any, category: any) {
    this.categoryChildren = children;
    this.currentCategory = category;
    this.showCategoryChildren = true;
  }

  hideChildren() {
    this.showCategoryChildren = false;
  }

  /**
   * Collectives customer user service logout
   * @param login
   */
  collectiveCustomerUserServiceLogout() {
    localStorage.clear();

    let _ = this.userService.logout();
    if (_ !== null) {
      _.subscribe(
        () => {
          document.location.href = '/';
        },
        () => {
          document.location.href = '/';
        }
      );
    }
  }

  // # /*
  // # |--------------------------------------------------------------------------
  // # | Search
  // # |--------------------------------------------------------------------------
  // # |
  // # */
  search: any = {
    per_page: 12,
    page: 1,
    client_marketplace_id: 1,
    query: '',
    start_at: new Date(),
    highlight_pre_tag: '<strong style="color: #831843;">',
    highlight_post_tag: '</strong>',
  };
  searchResults: any;
  clientMarketplaceCoreProductServiceIndex() {
    this.clientMarketplaceCoreProductService.setSearch(this.search);
    this.showSuggestions = true;
  }

  clientMarketplaceCoreProductServiceClear() {
    this.search.query = '';
    this.clientMarketplaceCoreProductServiceIndex();
  }

  clientMarketplaceCoreProductServiceRedirectSearch() {
    if (this.search.query != '' && typeof this.search.query !== 'undefined') {
      this.router.navigate(['/s/query/' + this.search.query.replace(/\s+/g, '-')]);
      this.searchHistory = this.searchHistory || [];
      var search = {
        name: this.search.query,
        url: '/s/query/' + this.search.query.replace(/\s+/g, '-'),
      };
      this.searchHistory.push(search);
      localStorage.setItem('search_history', JSON.stringify(this.searchHistory));
    } else {
      this.router.navigate(['/s']);
    }
  }

  hideMenu: boolean = true;

  onHideMobileMenu() {
    this.hideMenu = !this.hideMenu;
  }

  cleaning: boolean = false;
  kits: boolean = false;
  laundry: boolean = false;

  onShowCategoryChildren(data: any) {
    this[data] = !this[data];
  }

  drawerOpen = false;

  toggleDrawer() {
    this.drawerOpen = !this.drawerOpen;
  }

  clearSearch() {
    this.search.query = '';
  }

  zipcode: any;
  /**
   * Cores localization zipcode service show
   * @param event
   */
  coreLocalizationZipcodeServiceShow() {
    var _ = this.coreLocalizationZipcodeService.view({ zipcode: this.zipcode });
    if (_ !== null) {
      _.subscribe(
        (response: any) => {
          this.coreLocalizationZipcodeService.setZipcode(response.data);
          this.drawerOpen = false;
          this.showCepPopup = false;
        },
        (err) => {
          this.messageService.add({ severity: 'error', detail: err.error.not_found });
          this.messageIsError = this.coreLocalizationZipcodeService.error.throw(err);
        }
      );
    }
  }

  showSuggestions = false;

  onFocus() {
    if (this.searchHistory?.length > 0 || this.searchResults?.data || this.search.query !== '') {
      this.showSuggestions = true;
    } else if (this.search.query == '' && this.searchResults?.data) {
      this.searchResults = '';
    }
  }

  onBlur() {
    setTimeout(() => {
      this.showSuggestions = false;
    }, 200); // Timeout para permitir clicar nas sugestões
  }

  onClearSearchHistory() {
    this.searchHistory = [];
    localStorage.removeItem('search_history');
  }

  onClearSearchItem(data: any) {
    var i = this.searchHistory.indexOf(data);
    if (i >= 0) {
      this.searchHistory.splice(i, 1);
      localStorage.setItem('search_history', JSON.stringify(this.searchHistory));
      this.searchHistory = JSON.parse(localStorage.getItem('search_history'));
    }
  }

  showProfileMenu: boolean = false;

  showCepPopup: boolean = false;
  showCleaningCategory: boolean = false;
  showKitCategory: boolean = false;
  showLaundryCategory: boolean = false;
  hidePopupTimeouts: { [key: string]: any } = {};

  togglePopup(popupType: string, show: boolean) {
    if (this.hidePopupTimeouts[popupType]) {
      clearTimeout(this.hidePopupTimeouts[popupType]);
    }
    if (show) {
      this.setPopupVisibility(popupType, true);
    } else {
      this.hidePopupTimeouts[popupType] = setTimeout(() => {
        this.setPopupVisibility(popupType, false);
      }, 100);
    }
  }

  setPopupVisibility(popupType: string, visible: boolean) {
    switch (popupType) {
      case 'cep':
        this.showCepPopup = visible;
        break;

      case 'cleaning':
        this.showCleaningCategory = visible;
        break;
      case 'kit':
        this.showKitCategory = visible;
        break;
      case 'laundry':
        this.showLaundryCategory = visible;
        break;
      default:
        break;
    }
  }

  onShowProfileMenu() {
    let profile = document.getElementById('profile');
    if (profile) {
      // Verifica se o elemento foi encontrado
      this.showProfileMenu = !this.showProfileMenu; // Alterna o valor booleano de showProfileMenu

      if (this.showProfileMenu) {
        profile.classList.add('w-9', 'h-9'); // Adiciona a classe 'w-9' se o menu for mostrado
      } else {
        profile.classList.remove('w-9', 'h-9'); // Remove a classe 'w-9' se o menu for oculto
      }
    }
  }

  // /**
  //  * Headers menu fixed on top
  //  */
  headerMenuFixedOnTop() {
    if (!this.actualUrl.includes('/social')) {
      var desktop_element = 'desktop_bar';
      var mobile_logo = 'logo';
      var banner_top = 'banner_top_header';
      var gum_index = ['fixed', 'w-full', 'top-0', 'drop-shadow-md'];
      var hide_index = ['hidden'];
      var hide_logo_index = ['hidden', 'lg:block'];

      var desktopElement = document.getElementById(desktop_element);

      if (desktopElement) {
        var gum = desktopElement.offsetTop;
        window.addEventListener('scroll', function () {
          var obj = document.getElementById(desktop_element);
          var banner = document.getElementById(banner_top);
          var logo = document.getElementById(mobile_logo);
          if (obj) {
            var y = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;

            if (y > obj.offsetTop && y > gum) {
              if (!obj.classList.contains(gum_index[0])) {
                obj.classList.add(...gum_index);
                banner.classList.add(...hide_index);
                logo.classList.add(...hide_logo_index);
                gum = y;
              }
            } else {
              if (obj.classList.contains(gum_index[0])) {
                obj.classList.remove(...gum_index);
                banner.classList.remove(...hide_index);
                logo.classList.remove(...hide_logo_index);
                gum = y;
              }
            }
          }
        });
      }
    }
  }
}
