import { environment } from 'src/environments/environment';
import { Observable, ReplaySubject, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// [[Providers]]
import { ErrorService } from '~services/error.service';
// ]
@Injectable()
export class CustomerUserService {
  authenticated_user: any;

  constructor(private router: Router, public http: HttpClient, public error: ErrorService) {
    this.authenticated_user = JSON.parse(localStorage.getItem('client_marketplace_customer_user'));
  }

  userSubject = new ReplaySubject();
  setUser(objectData: Object) {
    this.userSubject.next(JSON.stringify(objectData));
  }

  getUser() {
    return this.userSubject;
  }

  /**
   *
   * @param data
   */
  getQuerystring(data: any) {
    let querystring: any = '';
    for (let entry in data) {
      querystring += entry + '=' + encodeURIComponent(data[entry]) + '&';
    }
    return querystring;
  }

  /**
   *
   * @param data
   */
  getPost(data: any) {
    let postData: any = {};
    for (let entry in data) {
      if (typeof data[entry] == 'object') {
        if (data[entry] == null) {
          postData[entry] = null;
        } else {
          postData[entry] = JSON.stringify(data[entry]);
        }
      } else {
        postData[entry] = data[entry];
      }
    }
    return postData;
  }

  /**
   *
   * @param data
   */
  index(data: any) {
    let querystring = this.getQuerystring(data);

    if (this.authenticated_user !== null) {
      let api_token = this.authenticated_user.api_token;
      let request = this.http.get(environment.api_url + 'panel/client/marketplace/' + environment.marketplace.hostname + '/customer/user?' + querystring, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + api_token,
        },
      });
      return request;
    } else {
      return null;
    }
  }

  /**
   *
   * @param data
   */
  view(data: any) {
    let querystring = this.getQuerystring(data);

    if (this.authenticated_user !== null) {
      let api_token = this.authenticated_user.api_token;
      let request = this.http.get(environment.api_url + 'panel/client/marketplace/' + environment.marketplace.hostname + '/customer/user/' + data.id + '?' + querystring, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + api_token,
        },
      });
      return request;
    } else {
      return null;
    }
  }

  /**
   *
   * @param data
   */
  update(data: any) {
    let postData = this.getPost(data);

    if (this.authenticated_user !== null) {
      let api_token = this.authenticated_user.api_token;
      let request = this.http.put(environment.api_url + 'panel/client/marketplace/' + environment.marketplace.hostname + '/customer/user/' + data.id, postData, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + api_token,
        },
      });
      return request;
    } else {
      return null;
    }
  }

  /**
   *
   * @param data
   */
  update_password(data: any) {
    let postData = this.getPost(data);

    if (this.authenticated_user !== null) {
      let api_token = this.authenticated_user.api_token;
      let request = this.http.put(environment.api_url + 'panel/client/marketplace/' + environment.marketplace.hostname + '/customer/user/' + data.id + '/update_password', postData, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + api_token,
        },
      });
      return request;
    } else {
      return null;
    }
  }

  /**
   *
   * @param data
   */
  logout() {
    // let postData = this.getPost(data);

    if (this.authenticated_user !== null) {
      let api_token = this.authenticated_user.api_token;
      let request = this.http.put(environment.api_url + 'panel/client/marketplace/' + environment.marketplace.hostname + '/logout', {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + api_token,
        },
      });
      return request;
    } else {
      return null;
    }
  }

  /**
   *
   */
  profile() {
    let tk = this.authenticated_user == null ? null : this.authenticated_user.api_token;
    let id = this.authenticated_user == null ? null : this.authenticated_user.id;
    if (tk == null || id == null) {
      return of(null);
    }
    return this.http
      .get(environment.api_url_account + 'panel/client/marketplace/' + environment.marketplace.hostname + '/customer/user/' + id, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + tk,
        },
      })
      .pipe(
        catchError((error: any) => {
          if (error.status === 401) {
            localStorage.removeItem('client_marketplace_customer_user');
            return throwError(error);
          }
          if (error.status === 412 && error.error.message == 'Unauthenticated.') {
            localStorage.removeItem('client_marketplace_customer_user');
            return throwError(error);
          }
        })
      );
  }
}
