import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({ name: "html" })
export class UseHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  transform(content: string): any {
    return this.sanitized.bypassSecurityTrustHtml(content.replace(/\n/g, "<br/>"));
  }
}
