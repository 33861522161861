import { environment } from 'src/environments/environment';
// import "rxjs/add/operator/toPromise";
// import "rxjs/add/operator/catch";
// import "rxjs/add/observable/throw";
import { Observable, ReplaySubject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// [[Providers]]
import { ErrorService } from '~services/error.service';
// ]

@Injectable()
export class CollectiveMarketplaceCartService {
  authenticated_user: any;
  authenticated_cart: any;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, public http: HttpClient, public error: ErrorService) {
    this.authenticated_user = JSON.parse(localStorage.getItem('client_marketplace_customer_user'));
    this.authenticated_cart = JSON.parse(localStorage.getItem('client_marketplace_cart'));
  }

  cartSubject = new ReplaySubject();
  /**
   * Sets cart
   * @param Cart
   */
  setCart(Cart: any) {
    console.log('>>> CART: ' + Cart.client_marketplace_cart_uid);

    let authenticated_cart = JSON.stringify(Cart);

    // Salva em "Local Storage" para futura pesquisa.
    localStorage.setItem('client_marketplace_cart', authenticated_cart);
    // Atualiza requisição
    this.authenticated_cart = JSON.parse(authenticated_cart);

    // NEXT
    this.cartSubject.next(authenticated_cart);
  }

  getCart() {
    return this.cartSubject;
  }

  /**
   *
   * @param data
   */
  getQuerystring(data: any) {
    let querystring: any = '';
    for (let entry in data) {
      querystring += entry + '=' + encodeURIComponent(data[entry]) + '&';
    }
    return querystring;
  }

  /**
   *
   * @param data
   */
  getPost(data: any) {
    let postData: any = {};
    for (let entry in data) {
      if (typeof data[entry] == 'object') {
        if (data[entry] == null) {
          postData[entry] = null;
        } else {
          postData[entry] = JSON.stringify(data[entry]);
        }
      } else {
        postData[entry] = data[entry];
      }
    }
    return postData;
  }

  /**
   * Gets cart uuid
   * @param data
   * @returns
   */
  get_cart_uuid(data: any) {
    if (!data.hasOwnProperty('client_marketplace_cart_uid')) {
      // Carrinho já existente (Querystring)?
      if (this.activatedRoute.snapshot.params.hasOwnProperty('client_marketplace_cart_uid')) {
        return this.activatedRoute.snapshot.params.uid;
      } else {
        if (this.authenticated_cart !== null) {
          return this.authenticated_cart.client_marketplace_cart_uid;
        }
      }
    } else {
      return data.client_marketplace_cart_uid;
    }

    return '';
  }

  /**
   *
   * @param data
   */
  cart(data: any) {
    data.client_marketplace_cart_uid = this.get_cart_uuid(data);

    // Usuário logado?
    if (this.authenticated_user !== null) {
      data.client_marketplace_customer_id = this.authenticated_user.client_marketplace_customer_id;
    }

    let postData = this.getPost(data);

    let request = this.http.post(environment.api_url + 'collective/client/marketplace/' + environment.marketplace.hostname + '/cart', postData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return request;
  }

  /**
   *
   * @param data
   */
  view(data: any) {
    data.client_marketplace_cart_uid = this.get_cart_uuid(data);

    let querystring = this.getQuerystring(data);

    let request = this.http.get(environment.api_url + 'collective/client/marketplace/' + environment.marketplace.hostname + '/cart/' + data.client_marketplace_cart_uid + '?' + querystring, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return request;
  }

  /**
   *
   * @param data
   */
  price_delivery(data: any) {
    data.client_marketplace_cart_uid = this.get_cart_uuid(data);

    let querystring = this.getQuerystring(data);

    let request = this.http.get(environment.api_url + 'collective/client/marketplace/' + environment.marketplace.hostname + '/cart/' + data.client_marketplace_cart_uid + '/price_delivery?' + querystring, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return request;
  }

  /**
   *
   * @param data
   */
  apply_voucher_when_is_possible(data: any) {
    data.client_marketplace_cart_uid = this.get_cart_uuid(data);

    let postData = this.getPost(data);

    let request = this.http.put(environment.api_url + 'collective/client/marketplace/' + environment.marketplace.hostname + '/cart/' + data.client_marketplace_cart_uid + '/apply_voucher_when_is_possible', postData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return request;
  }

  /**
   *
   * @param data
   */
  apply_one_click_buy_when_is_possible(data: any) {
    data.client_marketplace_cart_uid = this.get_cart_uuid(data);

    let postData = this.getPost(data);

    let request = this.http.put(environment.api_url + 'collective/client/marketplace/' + environment.marketplace.hostname + '/cart/' + data.client_marketplace_cart_uid + '/apply_one_click_buy_when_is_possible', postData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return request;
  }

  /**
   *
   * @param data
   */
  empty_cart_address(data: any) {
    data.client_marketplace_cart_uid = this.get_cart_uuid(data);

    let postData = this.getPost(data);

    let request = this.http.put(environment.api_url + 'collective/client/marketplace/' + environment.marketplace.hostname + '/cart/' + data.client_marketplace_cart_uid + '/empty_cart_address', postData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return request;
  }

  /**
   *
   * @param data
   */
  empty_cart_subscription(data: any) {
    data.client_marketplace_cart_uid = this.get_cart_uuid(data);

    let postData = this.getPost(data);

    let request = this.http.put(environment.api_url + 'collective/client/marketplace/' + environment.marketplace.hostname + '/cart/' + data.client_marketplace_cart_uid + '/empty_cart_subscription', postData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return request;
  }

  /**
   *
   * @param data
   */
  empty_cart_voucher(data: any) {
    data.client_marketplace_cart_uid = this.get_cart_uuid(data);

    let postData = this.getPost(data);

    let request = this.http.put(environment.api_url + 'collective/client/marketplace/' + environment.marketplace.hostname + '/cart/' + data.client_marketplace_cart_uid + '/empty_cart_voucher', postData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return request;
  }

  /**
   *
   * @param data
   */
  put_delivery_zipcode(data: any) {
    let postData = this.getPost(data);

    let request = this.http.put(environment.api_url + 'collective/client/marketplace/' + environment.marketplace.hostname + '/cart/' + data.client_marketplace_cart_uid + '/put-delivery-zipcode', postData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return request;
  }

  /**
   * Obrigatório: Usuário logado;
   *
   * @param data
   * @returns
   */
  review(data: any) {
    data.client_marketplace_cart_uid = this.get_cart_uuid(data);

    let postData = this.getPost(data);

    if (this.authenticated_user !== null) {
      let api_token = this.authenticated_user.api_token;
      let request = this.http.get(environment.api_url + 'collective/client/marketplace/' + environment.marketplace.hostname + '/cart/' + data.client_marketplace_cart_uid + '/review', {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + api_token,
        },
      });
      return request;
    } else {
      return null;
    }
  }

  /**
   * Obrigatório: Usuário logado;
   *
   * @param data
   * @returns
   */
  apply_delivery_method(data: any) {
    data.client_marketplace_cart_uid = this.get_cart_uuid(data);

    let postData = this.getPost(data);

    if (this.authenticated_user !== null) {
      let api_token = this.authenticated_user.api_token;
      let request = this.http.put(environment.api_url + 'collective/client/marketplace/' + environment.marketplace.hostname + '/cart/' + data.client_marketplace_cart_uid + '/apply_delivery_method', postData, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + api_token,
        },
      });
      return request;
    } else {
      return null;
    }
  }

  /**
   * Obrigatório: Usuário logado;
   *
   * @param data
   * @returns
   */
  apply_shipping_address(data: any) {
    let postData = this.getPost(data);

    if (this.authenticated_user !== null) {
      let api_token = this.authenticated_user.api_token;
      let request = this.http.put(environment.api_url + 'collective/client/marketplace/' + environment.marketplace.hostname + '/cart/' + data.client_marketplace_cart_uid + '/apply_shipping_address', postData, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + api_token,
        },
      });
      return request;
    } else {
      return null;
    }
  }

  /**
   * Obrigatório: Usuário logado;
   *
   * @param data
   * @returns
   */
  apply_payment_method(data: any) {
    let postData = this.getPost(data);

    if (this.authenticated_user !== null) {
      let api_token = this.authenticated_user.api_token;
      let request = this.http.put(environment.api_url + 'collective/client/marketplace/' + environment.marketplace.hostname + '/cart/' + data.client_marketplace_cart_uid + '/apply_payment_method', postData, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + api_token,
        },
      });
      return request;
    } else {
      return null;
    }
  }

  /**
   * Obrigatório: Usuário logado;
   *
   * @param data
   * @returns
   */
  apply_payment_method_credit_card(data: any) {
    let postData = this.getPost(data);

    if (this.authenticated_user !== null) {
      let api_token = this.authenticated_user.api_token;
      let request = this.http.put(environment.api_url + 'collective/client/marketplace/' + environment.marketplace.hostname + '/cart/' + data.client_marketplace_cart_uid + '/apply_payment_method_credit_card', postData, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + api_token,
        },
      });
      return request;
    } else {
      return null;
    }
  }

  /**
   * Obrigatório: Usuário logado;
   *
   * @param data
   * @returns
   */
  apply_payment_method_installments(data: any) {
    let postData = this.getPost(data);

    if (this.authenticated_user !== null) {
      let api_token = this.authenticated_user.api_token;
      let request = this.http.put(environment.api_url + 'collective/client/marketplace/' + environment.marketplace.hostname + '/cart/' + data.client_marketplace_cart_uid + '/apply_payment_method_credit_card_installments', postData, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + api_token,
        },
      });
      return request;
    } else {
      return null;
    }
  }
}
