import { environment } from 'src/environments/environment';
import { Observable, ReplaySubject, of } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// [[Providers]]
import { ErrorService } from '~services/error.service';
// ]
@Injectable()
export class CollectiveMarketplaceService {
  constructor(private router: Router, public http: HttpClient, public error: ErrorService) {}

  marketplaceSubject = new ReplaySubject();
  setMarketplace(objectData: Object) {
    this.marketplaceSubject.next(JSON.stringify(objectData));
  }

  getMarketplace() {
    return this.marketplaceSubject;
  }

  /**
   *
   * @param data
   */
  getQuerystring(data: any) {
    let querystring: any = '';
    for (let entry in data) {
      querystring += entry + '=' + encodeURIComponent(data[entry]) + '&';
    }
    return querystring;
  }

  /**
   *
   * @param data
   */
  getPost(data: any) {
    let postData: any = {};
    for (let entry in data) {
      if (typeof data[entry] == 'object') {
        if (data[entry] == null) {
          postData[entry] = null;
        } else {
          postData[entry] = JSON.stringify(data[entry]);
        }
      } else {
        postData[entry] = data[entry];
      }
    }
    return postData;
  }

  /**
   *
   * @param data
   */
  marketplace() {
    return this.http.get(environment.api_url + 'collective/client/marketplace/' + environment.marketplace.hostname, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
  }
}
